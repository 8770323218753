.speech-wrapper {
  padding: 30px 40px;
  height: 70vh;
  overflow: auto;
}
.speech-wrapper .bubble {
  width: 240px;
  height: auto;
  display: block;
  background: #f5f5f5;
  border-radius: 4px;
  box-shadow: 2px 8px 5px #000;
  position: relative;
  margin: 0 0 25px;
}
.speech-wrapper .bubble.alt {
  margin: 0 0 0 584px;
}
.speech-wrapper .bubble .txt {
  padding: 8px 55px 8px 14px;
}
.speech-wrapper .bubble .txt .name {
  font-weight: 600;
  font-size: 12px;
  margin: 0 0 4px;
  color: #3498db;
}
.speech-wrapper .bubble .txt .name span {
  font-weight: normal;
  color: #b3b3b3;
}
.speech-wrapper .bubble .txt .name.alt {
  color: #2ecc71;
}
.speech-wrapper .bubble .txt .message {
  font-size: 12px;
  margin: 0;
  color: #2b2b2b;
}
.speech-wrapper .bubble .txt .timestamp {
  font-size: 11px;
  position: absolute;
  bottom: 8px;
  right: 10px;
  text-transform: uppercase;
  color: #999;
}
.speech-wrapper .bubble .bubble-arrow {
  position: absolute;
  width: 0;
  bottom: 42px;
  left: -16px;
  height: 0;
}
.speech-wrapper .bubble .bubble-arrow.alt {
  right: -2px;
  bottom: 40px;
  left: auto;
}
.speech-wrapper .bubble .bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid #f5f5f5;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(145deg);
}
.speech-wrapper .bubble .bubble-arrow.alt:after {
  transform: rotate(45deg) scaleY(-1);
}

.chat__form {
  background-color: #e0e0e000;
}
.chat__form form {
  max-width: 800px;
  margin: 0 auto;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat__form input {
  height: 40px;
  font-size: 16px;
  min-width: 90%;
  padding-left: 15px;
  background-color: #fff0;
  border-radius: 15px;
  border: none;
}
.scrollerchatlist {
  height: 81vh;
  overflow: auto;
}
.chat__form button {
  width: 10%;
  height: 100%;
  font-size: 16px;
  background-color: transparent;
  border: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.chat__form button:hover {
  font-weight: bold;
}
