.mwrapper .mleft .react-calendar{
width:600px;
}

.medical-calendar .react-calendar button{
height:80px;
}

.medical-calendar .react-calendar__tile--now{
height:80px;
}

.medical-calendar .react-calendar__tile--active{
height:80px;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {

border-radius: 50%;
}


.react-calendar__tile--active{
    border-radius: 50%;
}
.react-calendar__tile {
    color: white;
  }