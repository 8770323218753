.timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  height: 800px;
}

.timeline-post {
  display: flex;
  flex-direction: column;
  background-color: #ffffff00;
  border: 1px solid #dddddd00;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.timeline-post-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.timeline-post-header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.timeline-post-text {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
}

.timeline-post-actions {
  display: flex;
  gap: 10px;
}


.box {
  transition: box-shadow .3s;
  min-width: 200px;
  max-width: 400px;
  height: 90px;
  margin: 50px;
  border-radius:10px;
  padding-left: 10px;
  float: left;
  font-size: bold;
  border:.5px solid rgb(179, 92, 150)
}
.box:hover {
  box-shadow: 0 0 11px rgba(226, 18, 171, 0.2); 

  


}