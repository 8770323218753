.react-tabs__tab-list{
width:40.2rem;
list-style:none;
display:flex;
justify-content:space-between;
border-top-left-radius:10px;
border-top-right-radius:10px;
background:#651c6a;
}

.react-tabs__tab{
  width:50%;
  padding-top:1rem;
  margin-bottom:0px;
}

.react-tabs__tab--selected{
/*  border-top-left-radius:10px;
  border-top-right-radius:10px;*/
  width:50%;
  border:none;
  border-bottom:2px solid red;
}


.react-tabs__tab-panel{
margin-top: -0.6rem;

}

#react-tabs-0{
 border-top-left-radius:10px;
}

#react-tabs-2{
border-top-right-radius:10px;
}




.react-tabs__tab--selected::after{
background:none;
}

