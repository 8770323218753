sectionClass {
  padding: 80px 0px 50px 0px;
  position: relative;
  display: block;
  background: rgb(249, 249, 249);
}

.row {
  width: 980px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.row:before,
.row:after {
  content: "";
  display: table;
}
i.fa.fa-calendar {
  color: white;
  font-size: 12px;
}

.cbp_tmtimeline > li .cbp_tmicon2 {
  width: 50px;
  height: 50px;
  top: 3%;

  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #151515;
  background: rgb(232, 26, 127);
  border-radius: 50%;
  text-align: center;
  left: 115px;
  margin: 0 0 0 -25px;
}
.todaysdate {
  position: relative;
  left: -161px;
  top: -9px;
  font-size: 14px;
}

.sectiontitle {
  background-position: center;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101f2e;
}
i.fa-solid.fa-baseball {
  top: 37%;
  color: #ffffff;
  position: relative;
}
.fullWidth {
  width: 100%;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 70%;
  position: relative;
}
.cbp_tmicon img {
  top: 40%;
  left: 42%;
  position: inherit;
  width: 27px;
}
/********************************/
/*  SECTION WORK EXPERIENCE
********************************/

#work-experience .sectiontitle .headerLine {
  width: 280px;
}

#work-experience .headerline {
  width: 280px;
}
#workexperience {
  width: 80%;
}

.cbp_tmtimeline {
  margin: 60px 30px 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 3%;
  bottom: 0;
  width: 10px;
  background: #e81a7f;
  left: 13%;
  height: 100%;
}

.cbp_tmtimeline li:last-child:before {
  content: initial;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2.9em;
  color: #3594cb;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #6cbfee;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: rgb(232, 26, 127);
  color: #fff;
  padding: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  font-family: "Open Sans";
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}
.cbp_tmtimeline > li .cbp_tmlabel2 {
  margin: 0 0 15px 25%;
  text-align: justify;
  background: rgba(50, 68, 84, 0);
  color: #fff;
  padding: 30px;
  font-size: 14px;
  /* font-weight: 300; */
  line-height: 1.6;
  /* font-family: "Open Sans"; */
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}
.cbp_tmtimeline > li .cbp_tmlabel3 {
  margin: 0 0 15px 25%;
  text-align: justify;
  background: rgba(50, 68, 84, 0);
  color: #fff;
  padding: 30px;
  font-size: 14px;
  /* font-weight: 300; */
  line-height: 1.6;
  /* font-family: "Open Sans"; */
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: rgb(232, 26, 127);
}

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  margin-top: 0px;
  color: white;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  opacity: 0.7;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  text-align: left;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 i {
  margin-right: 5px;
  vertical-align: middle;
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  background: rgba(232, 26, 126, 0);
  border-width: 10px;
  top: 70px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  background: rgb(232, 26, 127);
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 150px;
  height: 150px;
  top: 3%;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #151515;
  background: rgb(232, 26, 127);
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}

.cbp_tmtimeline li {
  margin-bottom: 70px;
  position: relative;
}

.sectionClassProject {
  position: relative;
  display: block;
  /* background: #f7f7f7; */

  margin: 0 auto;
  padding: 80px 1.875em 3.125em;
}

.projectParagraph {
  font-size: 18px;
  margin: 0.5em 0 0;
  font-family: "Source Sans Pro", serif;
}

.projectParagraphLink {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-top: 50px !important;
  margin-bottom: 0px;
  text-align: right;
}

.projectParagraphLink a {
  color: white;
  text-decoration: underline;
}

/* .cbp_tmicon img {
  width: 100%;
} */

.faPra {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 70px;
  vertical-align: middle;
  color: white;
  line-height: 150px;
}

.label {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  color: #ffffff;
  display: inline;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 15px;
}

.date {
  color: #bfc3c7;
  display: block;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  right: 20px;
}

.date i {
  margin-right: 8px;
  vertical-align: top;
  font-size: 18px;
  line-height: 20px;
}

@media (max-width: 1024px) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 30px 0 70px 0;
    padding: 50px 30px 30px 30px;
    font-weight: 400;
    font-size: 95%;
    float: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    border-right-color: transparent;
    border-bottom-color: rgb(50, 68, 84);
    top: -20px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: rgb(43, 58, 72);
    left: 65px;
  }
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmlabel:after {
    right: 65px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmicon {
    position: relative;
    float: left;
    left: auto;
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media (max-width: 32em) {
  .cbp-ntaccordion {
    font-size: 70%;
  }
}
/* AUTHOR LINK */

footer {
  z-index: 100;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  bottom: 0;
  left: 0;
}

footer p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  opacity: 0;
  font-family: "Open Sans";
  width: 100%;
  word-wrap: break-word;
  line-height: 25px;
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  margin: 0;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  transition: all 250ms ease;
}

footer .authorWindow a {
  color: white;
  text-decoration: none;
}

footer p strong {
  color: rgba(255, 255, 255, 0.9);
}

.about-me-img {
  width: 120px;
  height: 120px;
  left: 10px;
  /* bottom: 30px; */
  position: relative;
  border-radius: 100px;
}

.about-me-img img {
}

.authorWindow {
  width: 600px;
  background: #75439a;
  padding: 22px 20px 22px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.authorWindowWrapper {
  display: none;
  left: 110px;
  top: 0;
  padding-left: 25px;
  position: absolute;
}

.trans {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.flex-pc {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .innerbox2 {
    width: 134px !important;
  }
  .flex-pc {
    display: block;
    justify-content: space-around;
  }
  .display-mob-nun {
    display: none !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .authorWindow {
    width: 210px;
  }
  .sectionClass {
    margin: auto 5%;
  }
  .authorWindowWrapper {
    bottom: -170px;
    margin-bottom: 20px;
  }

  footer p {
    font-size: 14px;
  }
}
