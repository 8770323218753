.carousel-root {
  height: 18rem;
  margin: 5%;
}

/* .carousel {
  /* height: 10rem; */

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  height: 100%;
}

.carousel .caption {
  position: absolute;
  z-index: 5;
  top: 0.6rem;
  bottom: 0rem;
  text-align: left;
  /* background:#030054; */
  line-height: 10px;
  padding: 10px;
}
.carousel .caption2 {
  position: absolute;
  z-index: 5;
  top: 0.6rem;
  width: 100%;

  bottom: 0rem;
  right: 11%;
  text-align: center;
  /* background:#030054; */
  line-height: 10px;
  padding: 10px;
}
.carousel .caption3 {
  position: absolute;
  z-index: 5;
  top: 0.6rem;
  width: 100%;

  bottom: 0rem;
  right: -30%;
  text-align: left;
  /* background:#030054; */
  line-height: 10px;
  padding: 10px;
}
.bluebutton {
  right: 0%;
  top: 35%;
  color: white;
  position: absolute;
  border-radius: 5px;
  padding: 10px 70px;
  background: skyblue;
  border: 1px solid skyblue;
}

.secbutton {
  right: 41%;
}

.carousel .caption span {
  font-size: 12px;
}

.carousel img {
  height: 10rem;
}

.thumbs {
  display: none;
}

.carousel-status {
  display: none;
}

.control-arrow {
  background: #bd7ac285 !important;
  height: 50px !important;
  width: 50px;
  border-radius: 50%;
  top: 100px !important;
  opacity: 1 !important;
}

.control-dots .selected {
  width: 30px !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 600px) {
  .control-arrow {
    top: 35px !important;
    opacity: 1 !important;
  }
  .bluebutton {
    top: 50%;
    padding: 5px 30px;
  }
}
