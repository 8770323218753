.react-calendar__tile:disabled {
  background-color: #52135b00;
  color: grey !important;
}
button.react-calendar__navigation__label {
  color: white;
}
.react-calendar__navigation button[disabled] {
  background-color: #4a0d8700;
}
.clicked {
  background-color: #e81a7f !important;
}
.time-button {
  cursor: pointer;
  color: aliceblue;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  /* background-color: rgb(107, 21, 114); */
}
.bookbutton {
  padding: 2%;
  background: #e81a7f;
  border: 1px solid rgb(34 102 24);
}
.tabcss {
  max-width: 261px;
  display: block;

  position: absolute;
  height: 230px;
}

@media screen and (min-width: 600px) {
  .mobilebooking {
    top: 10%;
  }

  .timerslecter {
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 600px) {
  .timerslecter {
    /* width: 260px !important; */
    margin: 0 auto !important;
    margin-top: 5% !important;
  }
  .containcallander {
    width: 260px !important;
    margin: 0 auto !important;
  }
}
