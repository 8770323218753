.full-screen-slider {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .slide {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition:0.7s;
    
    
  }
  
  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    z-index: 1;
  }
  
  .prev-button {
    left: 20px;
  }
  
  .next-button {
    right: 20px;
  }
  