.brandname{
    margin: 0px;
    color:white;
    font-size: 100px;
    padding-top: 300px;
    padding-left: 60px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.label{
 margin-left: 70px;
 background-color: rgba(58, 75, 90,0);

}
.button{
    display: block;
    color:white;
    background-color: rgba(58, 75, 90,0);
    border:2px solid white;
    width: 120px;
    height: 40px;
    border-radius: 15px;
    margin-left: 90px;
    margin-top: 15px;
}
.button:hover{
    background-color: white;
    color:black;
    width:125px;
    height: 43px;
    transition: .4s;
    font-size: 19px;
}

@media(width<500px) {

.brandname{
    font-size: 50px;
    margin:auto;

}
.button{
    display: block;
    margin:auto;
    margin-top: 9px;
}
.label{
    display: block;
    margin:auto;
    padding-left: 73px;
}
}