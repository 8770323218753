.paginationn {
  display: inline-block;
}

.paginationn a {
  color: black;
  float: left;
  padding: 1px 9px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.paginationn a.active {
  background-color: rgb(114, 76, 175);
  color: white;
  border: 1px solid #4caf50;
}

.paginationn a:hover:not(.active) {
  background-color: #ddd;
}
