.medical-detail tr{
	margin-bottom: 10px;
	line-height: 30px;
}

.medical-detail tr td{
 text-align: left;
 font-size: 15px;
}

.medical-detail tr td input{
	border: none;
}