body {
  background: url(bg.png) no-repeat no-repeat fixed;
  background-size: cover;
  overflow: overlay;
  font-family: "Poppins", sans-serif !important;

  text-transform: capitalize;
}
#root {
  height: 100vh;
}
.date-input-container {
  position: relative;
}

/* Style for the calendar icon */
.date-input-container .fa-calendar {
  position: absolute;
  /* Adjust as needed */
  top: 65%;
  transform: translateY(-50%);
  font-size: 12px !important; /* Adjust icon size as needed */
  color: black !important; /* Adjust icon color as needed */
  cursor: pointer;
}

i.fa.fa-calendar {
  color: black;
  font-size: 12px;
}

.date-input-container .fa-calendar {
  position: absolute;
  right: 0;
  left: 91px;
  top: 65%;
  transform: translateY(-50%);
  font-size: 16px;
  color: black;
  cursor: pointer;
}
/* @font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url("./fonts/Gilroy-ExtraBold.opentype") format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
/* } */
td input {
  text-transform: capitalize;
}
@font-face {
  font-family: "GilroyExtraBold";
  src: url("./fonts/GilroyExtraBold.otf") format("opentype");
}
.menu {
  cursor: pointer;
  display: none;
}
.width-50 {
  width: 50%;
}
.select-option option {
  background-color: #000;
}

/* Modal Container */
.modalbody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content */
.modal-inner {
  background-color: #fff; /* Modal background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a box shadow for the modal */
  text-align: center;
  position: relative;
}

/* Modal Close Button */
.modal-inner button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

button {
  display: block;
  background-color: #e81a7f;
  border: 1px solid #ced4da; /* Adding a border similar to Bootstrap */
  font-size: 16px;
  padding: 10px 20px; /* Adjusted padding for better appearance */
  color: aliceblue;
  margin: auto;
  border-radius: 4px; /* Using a smaller border-radius for a more typical button shape */
  top: 4%;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease; /* Adding a transition for hover effect */
}

button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-weight: bold; /* Adding a subtle box shadow on hover */
  transition: 3s;
  border-radius: 6px;
  
}


/* You can add more styles to customize the appearance further */

/* Modal Image */
.modal-inner img {
  max-width: 100%;
  max-height: 80vh; /* Limit the height of the image */
}

/* Center the image vertically */
.modal-inner img {
  display: block;
  margin: 0 auto;
}

.blur {
  box-shadow: rgba(0, 0, 0, 0.16) 12px 10px 60px;
  backdrop-filter: blur(39px);
  background: rgba(0, 0, 0, 0.16);
  top: 5%;
  position: absolute;
}
.intialform {
  color: white;
}
.intialform input {
  border: none;
  padding: 2% 10% !important;
  border-radius: 10px;
}
.inputfile {
  border: none;
  padding: 3% 10% !important;
  border-radius: 10px;
}
.gilroy {
  font-family: "GilroyExtraBold";
}

/* Default scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.452);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

/* Styles when hovering over the element */
:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
}

:hover::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

.boderlessinsput {
  background: #f0f8ff00;
  border: none;
  font-size: 16px;
  color: white;
}
/* .res-calendar{
width: 260px;
float: right;
position: absolute;
right: 7rem;
top: 6rem;
<<<<<<< HEAD
}

.expert-list{
position:absolute;
bottom:1em;
=======
} */

.success {
  box-shadow: rgba(0, 0, 0, 0.16) 12px 10px 60px;
  -webkit-backdrop-filter: blur(39px);
  backdrop-filter: blur(39px);
  background: rgba(0, 0, 0, 0.16);
  /* top: 5%; */
  position: absolute;

  width: 40%;
  text-align: center;
  padding: 5%;
  border-radius: 10px;
  color: white;
  font-size: 23px;
}
.llogi {
  text-align: center;
  margin: 2% 0%;
}

.medical-edit input {
  margin-bottom: 20px;
  width: 25%;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  color: rgb(255, 255, 255);
  padding: 5px;

  border: none;
  padding: 2% 10% !important;
  border-radius: 10px;
}
.box8 {
  display: flex;
  justify-content: space-around;
}
.inputbox {
  position: fixed;
  font-size: 16px;
  top: 50%;
  left: 40%;
  width: 500px;
  background: white;
  height: 370px;
  border-radius: 10px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgb(17 25 40 / 53%) !important;
}
.lightgradient2 {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11) !important;
}
.trfortable {
  margin: 10px auto;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  cursor: pointer;
}
.expert-list {
  /* position: absolute; */
  bottom: 1em;
}

.App {
  text-align: center;
  display: flex;
  background: url("bg.png") no-repeat;
  background-size: cover;
}

a {
  text-decoration: none;
  color: #fff;
}
.avatar2 {
  vertical-align: middle;
  width: 133px;
  height: 66px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.overview {
  /* background:linear-gradient(45deg, #7f1f54 70%, #3a51a7);*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}
.width-100 {
  width: 100%;
}
.clintscroll {
  min-height: 0;
}
.font-16 {
  font-size: 16px !important;
}

.newbg {
  background: url("newbg.png") no-repeat;
  /* background-size: cover; */
  background-size: cover;
  overflow: hidden;
  /* height: 100vh; */
}
.mwrapper {
  display: flex;
  /*background:linear-gradient(45deg, #b53899 70%, #3a51a7);*/
}

table {
  width: 40rem;
}

table tr td {
  font-size: 16px;
}
.inndert {
  display: flex;
  flex: 1;
}
.inndert p {
  margin: auto 0;
}
.exter {
  flex: 0 0 66%;
}
.ovter {
  display: flex;
}
.oker {
  flex: 0 0 100%;
}

tr td button {
  background: #00acc8;
  padding: 4px 20px;
  border: 1px solid #00acc8;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

tr td select {
  padding: 4px 20px;
  border: 1px solid #00acc8;
  color: #333;
  font-weight: bold;
  border-radius: 5px;
}

.mwrapper .mleft {
  width: 95%;
}

.profile a {
  text-decoration: none;
  color: #fff;
}

.newscrooler {
  height: 500px;
}

.profile .info small {
  border-bottom: 1px solid #fff;
  line-height: 20px;
}

.react-calendar.c1.c2 {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgb(17 25 40 / 53%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.grandiant {
  padding: "2% 10%";
  text-align: "right";
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}
.measure {
  justify-content: space-evenly;
}
abbr {
  font-size: 10px;
}

.serchdocp {
  margin: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.payment-container .inner-flex {
  display: flex;
  justify-content: space-between;
}

.appointment-tabs .appointment-inner .aleft {
  display: flex;
}

.appointment-tabs .appointment-inner {
  display: flex !important;
  margin: auto;
  flex-direction: column;
  text-align: left;
}

/********** react tabs customization ***************/

.react-tabs {
  width: -moz-available;
}

.react-tabs__tab-list {
  overflow-x: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100% !important ;
}

.multiple-tab .react-tabs__tab-list {
  width: 100% !important;
}

.react-tabs__tab-list::-webkit-scrollbar {
  display: none;
}

.react-tabs__tab-panel {
  /* overflow-x: scroll; */
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.loginimage {
  width: 35%;
}
.outerdivnotes textarea {
  color: white !important;
}
.note-container iframe {
  width: -webkit-fill-available !important;
}
.pinkbutton {
  background: rgb(232, 26, 127);
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.slogo3 {
  width: 426px;
}

.carousel .item {
  height: 300px;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 300px;
}
.pinkbutton2 {
  background: rgb(232, 26, 127) !important;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.bluebutton2 {
  border-radius: 5px;
  padding: 10px 70px;
  background: #00acc8;
  border: 1px solid #00acc8;
  background: #00acc8;
}
.fileboder {
  border-bottom: none !important;
}
.calanderbutton {
  background: #ffffff1c 0% 0% no-repeat padding-box;
  border: none;
  color: white;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}
button.react-calendar__navigation__label {
  color: black !important;
}
/* .tabview::-webkit-scrollbar {
  display: block !important;
} */
::-webkit-scrollbar:not(.tabview) {
  display: none;
}
/* .tabview::-webkit-scrollbar {
  display: block !important;
} */
.porpssidebar li {
  background: #6a0f4b;
}
.admintabs {
  background: #6a0f4b;
}
.tableforsymtm {
  display: flex;
  /* width: 100%; */

  flex-flow: row wrap;
  position: relative;
}
.tablegrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.trforsymtm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 4px;
  /* flex: 0 1 calc(20% - 8px); <-- adjusting for margin */
}
.marigin-auto {
  margin: 2% 2%;
}
.scrollerbar {
  overflow-y: scroll;
  height: 64vh;
}

.avatar {
  vertical-align: middle;
  width: 66px;
  height: 66px;
  border-radius: 50%;
}
.gradiantblur {
  background: #ffffff3d 0% 0% no-repeat padding-box !important;
}
::-webkit-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.tdoneline {
  text-align: initial;
  font-size: 12px;
}
.greet {
  font-size: 14px;
  font-family: "GilroyExtraBold";
}
.counter-card {
  margin: 10px auto;
  margin-top: 10px;
}
.counter-collect {
  font-size: 14px;
  justify-content: space-evenly;
  /* padding: 5%; */
  /* border-right: 2px soild pink; */
  height: 20%;
}
.leftbox {
  margin: auto;
  padding: 5%;
  border-right: 2px solid pink;
}
.minus-margin {
  margin: auto;
  font-size: 16px;
}
.docoptions option {
  background-color: #490a48b6;
}
.table-cover {
  overflow-y: scroll;
  height: 86%;
  overflow-x: hidden;
}
.height-600 {
  height: 600px;
}

.formobile {
  overflow-y: scroll;
  height: 400px;
  overflow-x: hidden;
}

.profile {
  height: auto !important;
  border-radius: 10px !important;
  box-shadow: rgb(51 51 51) 2px 2px 1px !important;
  font-size: 12px !important;
  padding: 20px !important;

  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11) !important;
  text-align: left !important;
  list-style: none !important;
  position: absolute !important;
  right: 8.6% !important;
  top: 7% !important;
  width: 170px !important;
  z-index: 5 !important;
}
.profile li {
  padding: 10px !important;
}
.scrooler {
  overflow: scroll;
}
.imgp {
  width: 35% !important;
  position: fixed;
}
.entry-content {
  padding: 5%;
}
.entry-content h2 p {
  font-family: "GilroyExtraBold";
}

.entry-content h2 strong {
  font-size: 56px;
}
.entry-content h3 strong {
  font-size: 36px;
}
.privacyback {
  display: flex;
  background: url("privacybg.png"), no-repeat;
  background-size: cover;
  overflow: overlay;
  height: 100vh;
}

.pricegrid {
  display: block;
}
.prices {
  display: flex;
  justify-content: space-around;
}
.personal-edit-form div {
  /* background: rgb(83 14 115 / 39%) !important; */
  background: "rgb(139 72 143 / 0%)" !important;
}
.medical-edit-form input {
  display: inline-block;
  width: 150px;
  height: 30px;
  /* background: rgb(83 14 115 / 39%) !important; */
}
.medical-edit-form input[type="submit"] {
  background: rgb(232, 26, 127) !important;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.medical-edit-form label {
  display: inline-block;
  width: 300px;
  height: 30px;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}

.medical-edit label {
  display: inline-block;
  width: 300px;
  height: 30px;
  /* background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11); */
}
.medical-edit select {
  float: right;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}
.medical-edit select {
  margin-bottom: 20px;
  /* width: 25%; */
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  color: rgb(255, 255, 255);
  padding: 5px;
  border: none;
  padding: 2% 19% !important;

  border-radius: 10px;
}
.medical-edit select option {
  background-color: #1144ff;
  color: rgb(255, 255, 255);
}

.medical-edit file {
  float: right;
  /* background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11); */
}
.medical-edit input {
  float: right;
  /* background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11); */
}
.finish-button {
  text-align: center !important;
}
.finish-button input {
  float: none;
}
/* .medical-edit-form small {
  background: rgb(83 14 115 / 39%) !important;
} */
.vh-100 {
  height: 100vh;
}

.aquamarine {
  background: aquamarine;
}
.lightgradient {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}
.react-tabs__tab-list {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11) !important;
}
.dategrid {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11) !important;
}
.tableoffood td {
  font-size: 12px !important;
}
input.hiddentd {
  width: 0px;
}
.flex-100 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.flex-100chat {
  display: flex;
  width: 100%;
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.alignstart {
  text-align: start;
  font-size: 16px;
  font-weight: 600;
}
.d6 {
  padding: 2%;
  border-bottom: 2px solid grey;
}
.pink-input {
  border: none;
  color: #ffffff;
  background: #a05a8e;
}
.margin-0 {
  margin: 0;
  font-size: 14px;
}
.livechat {
  width: 60%;
  border-top: 1px solid white;
}
.chattype {
  border: none;
}
.chattype::placeholder {
  color: white;
}
.timefont {
  text-transform: uppercase;
  font-size: 16px;
}
.w-100 {
  width: 100%;
}
.w-40 {
  width: 40%;
  justify-content: space-between;
}
.w-60 {
  width: 60%;
}
.chat-avatar {
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #ffffff2b;
  padding: 3%;
  justify-content: space-between;
}
.chat-header {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding-top: 1%;
  padding-bottom: 1%;
}
.chatcontats {
  border-right: 3px solid white;
  width: 40%;
}
.msserserch {
  padding: 4%;
  width: 90%;
  border: none;
}

.margin-90-auto {
  width: 95%;
  margin: auto;
}
.flex-1001 {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
.personal-edit-form label {
  font-size: 14px;
  display: inline-block;
  width: 150px;
  margin-bottom: 19px;
  /* background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11); */
}
.nobackground {
  background: "rgb(139 72 143 / 0%)" !important;
}
.subslex {
  display: flex;
}
.absulte {
  position: absolute !important;
  width: 65% !important;
  /* backdrop-filter: blur(31px) brightness(-15%) opacity(0.3) !important; */
}
.tablecover2 {
  height: 185px !important;
}
.DOCTE {
  width: 75%;
}
.hun-100vh {
  min-height: 115vh !important;
}
.weekerwidth {
  width: 92.5% !important;
}
.texttransform {
  text-transform: none;
}
.inputfile {
  display: none;
}
.wrapper6 {
  position: relative;
}

.icon6 {
  height: 3.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 55%;
  left: 2px;
  transform: translateY(-50%);
}

.input6 {
  height: 33px;
  box-sizing: border-box;
  border: none;
  padding-left: 1.5rem;
}
.serchicon {
  justify-content: flex-start;
  width: hight;

  min-height: 47px;

  vertical-align: middle;
  /* margin: auto; */
  background: #ffffff3d 0% 0% no-repeat padding-box !important;
}
.fontin12 {
  font-size: 12px;
}
.nospace {
  border-spacing: 0px 1em !important;
}

.nospace tr td button {
  padding: 12px 52px;
}
.prupleblur {
  background: rgba(17, 25, 40, 0.53);
  backdrop-filter: blur(16px) saturate(180%);
}
.dailytabmobile #react-tabs-1 {
  height: 261px !important;
}
.dailytabmobile #react-tabs-3 {
  height: 261px !important;
}
.width-85 {
  width: 85%;
}
.cpersonaldeatils {
  padding: 10px;
  text-align: left;
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}
.chat__form button {
  color: aliceblue;
}
.transparentSelect {
  padding: 4px 20px;
  border: 1px solid #00acc800;
  color: #fff;
  font-weight: bold;
  /* border-radius: 5px; */
  /* border: none; */
  /* color: rgb(255, 255, 255); */
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  padding: 1px 51px;
}

.bcak {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
}
.chatformborder {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  border: 1px solid;
}
.editform.gradiantblur {
  width: 90%;
  border-radius: 13px;
  margin: auto;
}
.editform.gradiantblur input {
  background: #ffffff3d 0% 0% no-repeat padding-box !important;
  border: none;
  width: 100%;
}
.pwd-container {
  position: relative;
}

.pwd-container input {
  width: 95%;
}
.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 23px;
}
.headertable {
  font-size: 12px;
}
.doctaker2 div#react-tabs-5 {
  height: 261px;
}
.doctaker2 div#react-tabs-6 {
  height: 261px;
}
.doctaker2 div#react-tabs-7 {
  height: 261px;
}
.doctaker2 div#react-tabs-8 {
  height: 261px;
}
.doctaker2 {
  height: 261px;
}
.doctaker2 div#react-tabs-9 {
  height: 261px;
}
.doctaker2 div#react-tabs-11 {
  height: 261px;
}
.doctaker2 div#react-tabs-13 {
  height: 261px;
}
.subactivitypop {
  backdrop-filter: blur(13px) saturate(99%);
  -webkit-backdrop-filter: blur(13px) saturate(99%);
  background-color: rgba(255, 255, 255, 0.09);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 30% !important;
}
.fontsize50 {
  font-size: 50px;
}
.trnew {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin: 4px;
}
.displayintail {
  border-bottom: 1px solid;
  padding-bottom: 4%;
  display: flex;
  justify-content: space-evenly;
}
.marginauto {
  margin-left: auto;
  margin-right: auto;
  width: 230px;
}

.backdropersafari {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.pinkbutton6 {
  background: rgb(232, 26, 127);
  border: none;
  padding: 5px 26px;
  /* padding: 5% 5%; */
  color: white;
}
.font-13 {
  font-size: 13px;
}
.pupleblurede {
  background-color: rgb(111, 43, 104);
  border: none;
  padding: 5% 5%;
  color: white;
}
.glassblur {
  background: rgba(17, 25, 40, 0.53) !important;
  backdrop-filter: blur(16px) saturate(180%);
}

.leftbar canvas {
  height: 530.6px;
}
.transparent56 {
  font: 24px;
  color: #fff;
  border: none;
  background: #00acc800;
}
.width-10 {
  width: 10%;
  margin: auto;
}
.margin-auto {
  margin: 2% auto;
}
.margin-toper {
  padding: 3% 1% 0%;
}
.margin-auto2 {
  margin: auto 2%;
}
.fontsize-25 {
  font-size: 30px;
}
.flexspace {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 1%;
}
.inital {
  position: relative;
  right: -10%;
}
.margin-4 {
  margin-top: 4%;
}
.label6 {
  margin: 0px 13px;
  font-size: 20px;
}
.weekselctor {
  background: 0% 0% no-repeat padding-box padding-box rgba(125, 40, 119, 0);
  color: white;
  border: 1px solid #ffffff00;
}
.font-blue {
  background: 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);
  color: #00acc8;
}
.carousel-root3 {
  height: 8rem !important;
}
.grad h6 {
  margin: 5px;
}
.margin-01 {
  margin: 0px;
  font-size: 19px;
}
.grad {
  width: 350px;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  margin: 0px;
  background-image: linear-gradient(#262a82, #131541);
}
.display-flex {
  display: flex;
}
.width-60 {
  width: 60%;
  margin: auto;
}
span.Titlemobile {
  height: 36px;
}
.preg {
  width: 72px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(134, 45, 139);
  display: flex;
  justify-content: center;
  z-index: 999;
  align-items: center;
}

.modal-content {
  background: rgb(134, 45, 139);
  color: #fffdfd;
  padding: 20px;
  position: fixed;
  width: 50%;
  height: 50%;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  /* Centering adjustments */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close1 {
  position: absolute;
  top: 10px;
  right: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  cursor: pointer;
}
.notificationul {
  display: contents;
  height: 400px;
}
.notificationli {
  background: #660051;
  /* box-shadow: 25px 25px 50px #410034, -25px -25px 50px #8b006e; */
  list-style: none;
  margin: 7%;
}
.notification-unseen {
  padding: 11px 14px;
  background-color: #a9459a; /* Change this to your desired background color */
  color: white; /* Change this to your desired text color */
}
.notification-seen {
  padding: 11px 14px;
  background-color: #31132d73; /* Change this to your desired background color */
  color: white; /* Change this to your desired text color */
}
.notifcation_scroll {
  max-height: 344px;
  overflow-y: scroll;
}
.notification-container {
  max-height: 400px; /* Adjust the maximum height as needed */
  overflow-y: scroll; /* Force scrollbar to appear */
}
.medicalselect option {
  background-color: #000;
}
.salesmedical label {
  min-height: 3em;
  padding: 2% 5%;
  width: 85%;
  display: inline-block;
  box-sizing: border-box;
}
.notifcationbutton {
  width: 100%;
  border: none;
  color: white;
  background: #a9459a;
}
.custom-checkbox:disabled:checked {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.custom-checkbox:disabled:checked::after {
  content: "\2713";
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w100 {
  width: 100%;
  color: #000 !important;
}
.font-13 {
  font-size: 13px;
}

svg.svg-inline--fa.fa-android.fa-w-18 {
  font-size: 35px;
  color: aquamarine;
}
.table-height {
  height: 700px;
}

@media screen and (min-width: 600px) {
  .hidepc {
    display: none;
  }
  .toperpc {
    margin-top: 2%;
    font-size: 20px !important;
    width: 64%;
  }
  .hr-80 {
    min-width: 64%;

    margin-left: 0px;
  }
  .display-pc-nun {
    display: none;
  }

  .dateindex {
    transform: translate(25rem, 2rem) !important;
  }
  .newfulltab {
    width: 250%;
    background: rgba(17, 25, 40, 0.53) !important;
    -webkit-backdrop-filter: blur(16px) saturate(180%) !important;
    backdrop-filter: blur(16px) saturate(180%) !important;
  }
  .mobile-view {
    display: none;
  }
  .mobile-view-hidden {
    display: none !important;
  }
  .personal-edit-form {
    /* position: fixed !important; */

    top: 30rem !important;
    right: 25rem !important;
    left: 38% !important;
  }
  /* .medical-edit-form {
    /* position: fixed !important; */

  tr.font-blue {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
  }
  .w100 {
    width: 100%;
    color: #000;
  }
  .takerfull {
    /* background: rgba(17, 25, 40, 0.53) !important;
    backdrop-filter: blur(16px) saturate(180%) !important; */
    background: rgb(255, 255, 255) !important;
    backdrop-filter: blur(16px) saturate(180%) !important;

    height: 77% !important;
    /* width: 85% !important; */
  }
  .ct {
    color: black !important;
    background-color: white !important;
    border: 1px solid black;
  }

  .croll {
    overflow-y: scroll;
    margin: 2rem auto;
  }
  .inputofdiet {
    background: #ffffff3d 0% 0% no-repeat padding-box !important;
    border: none !important;
    color: white !important;

    font-size: 12px !important;
    /* font-weight: 800 !important; */
    margin-top: 10px;
    width: 112px !important;
    height: 12px;
  }

  .inputofsymtum {
    background: #ffffff3d 0% 0% no-repeat padding-box !important;
    border: none !important;
    color: white !important;

    font-size: 12px !important;
    /* font-weight: 800 !important; */
    margin-top: 10px;
    width: 150px !important;
    height: 15px;
  }
  .contration {
    justify-content: space-evenly !important;
  }
  .chekboxsignup {
    width: 10.8% !important;
  }
  .mainlogo {
    width: 100px;
    height: 100px;
  }
  .marign-5 {
    margin-left: 5%;
  }

  .invinsiveinput input {
    display: none;
  }
  .invinsiveinput td {
    padding: 5%;
  }
  input:checked ~ label {
    color: white;
    padding: 5%;
    margin: 5%;
    border-radius: 5%;
    background: red;
  }
  .timerslecter {
    border-radius: 5%;
  }

  .timeslect:active {
    background: rgb(248, 7, 7) !important;
  }
  button.react-calendar__tile.react-calendar__month-view__days__day {
    color: #f186c4;
  }
  .active3 li {
    background: #6a0f4b;
  }
  .react-calendar__tile:disabled {
    background-color: none;
    color: brown !important;
  }
  /* .react-calendar__month-view__days__day--weekend {
    //color: #d10000 !important;
  } */
  .tableoffood label {
    margin-right: 5%;
  }
  .doctor-profile input {
    border: none;
    color: rgb(255, 255, 255);
    background: 0% 0% no-repeat padding-box padding-box
      rgba(255, 255, 255, 0.11);
  }
  .tableoffood {
    /* margin-right: 5%; */
    table-layout: fixed;
  }
  .outerdivnotes {
    width: 96%;
  }
  a.active {
    background-color: #1144ff;
    color: #ffffff;
  }
  .iconsofprofile {
    margin-right: 0px !important;
    cursor: pointer;
    width: 40px !important;
    height: 23px !important;
  }
  .grandiant {
    padding: 2% 15% !important;
  }
  .iconsofgradiant {
    border-radius: 50% !important;
    margin-left: 1%;

    background: #6a0f4c73 !important;
  }
  .profile {
    background: "#ffffff1c 0% 0% no-repeat padding-box";
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }
  .close-icon {
    display: none;
  }
  .addnew {
    top: 70% !important;
  }
  .medicine-module {
    top: 10%;
    padding: 10px 16px !important;
  }
  .docmright {
    width: 60% !important;
    margin: 0 70px !important;
  }
  .clientscroll {
    height: 400px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .homeheight {
    height: 480px;
  }
  .homeheight500 {
    height: 500px;
  }
  .greet_mobile {
    display: none;
  }
  .doctorcards {
    margin-left: 0% !important;
  }
  .activity {
    margin-top: 2% !important;
    padding: 3%;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    /* border-bottom: 1px solid black !important; */
  }
  .homepurple {
    /* background: #00000029 0% 0% no-repeat padding-box; */
    box-shadow: 0px 6px 9px #00000029;
    border-radius: 0px 0px 30px 30px;
    opacity: 1;
    backdrop-filter: blur(30px) brightness(80%);

    -webkit-backdrop-filter: blur(30px);
  }
  .note-container {
    /* background: #ffffff1c 0% 0% no-repeat padding-box;
    backdrop-filter: blur(30px) !important; */

    width: auto !important;
  }
  .margin50 {
    margin: auto 50px;
  }

  /* display: flex;
    justify-content: space-around; */
  /* } */
  /* .flexspace h4 { */
  /* float: left; */
  /* } */

  .smallerdown {
    margin-top: 4%;
    height: 21px;
  }
  .weekselctor option {
    background: #9e4292;
  }
  .margin10 {
    margin: 10px auto;
  }
  .trin {
    display: block;
    float: left;
    width: 15%;
  }
  .trin2 {
    display: block;
    float: left;
    width: 30%;
  }
  .close {
    display: none;
  }
  .margin-profile {
    margin-right: 12rem;
  }

  .slogo {
    width: 300px;
  }
  .calandermobile {
    display: none;
  }
  .flexsign {
    display: flex;
  }
  .gridfr {
    grid-template-columns: 1fr 1fr !important;
  }
  .exereriscegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tarakerupdatesales {
    width: 70%;
    padding: 15px;
  }
}
@media screen and (max-width: 600px) {
  .hidemboile {
    display: none;
  }
  .w-40 {
    width: 100%;
    justify-content: space-around;
  }
  .trin {
    display: block;
    float: left;
    width: 46%;
  }
  .scoller {
    overflow: scroll;
  }
  .carousel-root {
    height: 6rem !important;
    margin: 5%;
  }
  .videoexerisise2 {
    width: 95% !important;
  }
  .flexspacemob {
    width: 100% !important;
  }
  .swichmobile {
    margin: 10% 10% !important;
  }
  .ovter {
    display: block;
    flex: 0 0 100%;
  }
  .inndert {
    display: block;
  }
  .formobiletoggle {
    display: block;
  }
  .displayintailmobile {
    display: block;
  }
  .datemobile {
    margin: auto !important;
    left: 50%;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%) !important;
  }
  .margin-auto2 {
    margin: 10% 10% !important;
  }
  .innersales_profile {
    width: 75%;
  }
  .client-salesprofile {
    position: unset !important;
  }
  .trgridexerseice {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .trgridexerseice td {
    margin: 10px auto;
    font-size: 12px;
  }
  .mobcalories {
    padding: 5%;
    display: block !important;
  }
  .newexersice {
    right: 5% !important;
  }
  td.invisbileinput3 {
    /* visibility: hidden; */
    display: none;
  }
  .invisbileinpu2 {
    visibility: hidden;
  }
  .tarakerupdatesales {
    width: 90%;
    padding: 10px;
  }
  .display-none {
    display: none;
  }
  .dateindex {
    transform: none;
    margin: auto 3%;
  }
  .mobilemarginauto {
    margin: auto !important;
  }
  .table3 {
    overflow-x: scroll !important;
  }
  svg.svg-inline--fa.fa-chevron-down.fa-w-14 {
    margin-left: 1rem !important;
  }
  .tablegrid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .exereriscegrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .gridfr {
    grid-template-columns: 1fr;
  }
  .activityeditform {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .reshedulediv {
    text-align: initial !important;
    margin-top: 5%;
  }
  .leftbox {
    width: 200px;
  }
  .pc-view-hidden {
    display: none !important;
  }
  .fontof12 tr td {
    font-size: 12px !important;
  }
  .newfulltab {
    transform: none !important;
    z-index: 10 !important;
    width: 82% !important;
  }
  .updatepop {
    position: absolute !important;
    transform: none !important;
    width: 80% !important;
  }
  .speech-wrapper {
    height: 54vh !important;
    min-height: 35vh !important;
  }
  .scrollerchatlist {
    height: 70vh !important;
  }
  .chat__form input {
    border: 1px solid white !important;
    min-width: 80% !important;
  }
  .chat__form form {
    display: flex;
    width: 100% !important;
    /* justify-content: space-between; */
  }

  .flex-100chat {
    height: 100% !important;
  }
  /* .chat__form {
    width: 80% !important;
  } */

  .chat__form button {
    color: aliceblue;
  }
  .chat__form form {
    background-color: #760c50;
    max-width: -webkit-fill-available !important;
  }
  .calanderpc {
    display: none;
  }
  .flexdisplay {
    display: block !important;
  }
  .pc-view-hidden {
    display: none !important;
  }
  .slogo {
    width: 300px;
  }
  .padding-0 {
    padding: 0 !important;
  }
  .clear-margin {
    width: 90%;
    margin: 15px 15px;
  }
  .margin-profile {
    margin-right: 0;
  }
  .hide-mob {
    display: none;
  }
  .trgridrow {
    display: grid !important;
    grid-template-rows: 2fr 2fr 2fr !important;
    grid-template-columns: 1fr !important;
    background: rgb(134 51 125 / 0%) !important;
    text-align: center !important;
  }
  .calori {
    display: block !important;
  }
  .pc-view {
    display: none;
  }
  .mobilelivechat {
    display: none;
  }
  .mobileback {
    background: #760c50;
  }
  .chatcontats {
    width: 100% !important;
  }

  .livechat {
    width: 100% !important;
    position: absolute !important;
  }
  .homeimage {
    width: 80%;
  }
  .mainlogo {
    width: 100px;
    height: 100px;
  }
  .outerdivnotes {
    width: 86%;
  }
  .Titlemobile {
    margin-top: 0rem !important;
  }
  .spflexmob {
    width: auto !important;
  }
  .eachitem {
    margin-left: 15%;
  }
  .homepurple {
    background: #00000029 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 9px #00000029;
    border-radius: 0px 0px 30px 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }
  .profile {
    background: "#ffffff 0% 0% no-repeat padding-box";
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }
  .cardoftraker {
    margin: 0px 10px !important;
  }
  .tableoffood {
    table-layout: fixed;
  }
  .tdoflabel {
    font-size: 14px !important;
    width: 5px !important;
  }
  .close-icon {
    float: right;
  }
  .tablecroll {
    margin: 0 auto;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border-spacing: 16px !important;
    border-collapse: initial !important;
  }
  .takerfull {
    display: flex;
    justify-content: space-between;
    /* background: rgba(17, 25, 40, 0.53) !important;
    backdrop-filter: blur(16px) saturate(180%) !important; */
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    /* width: 75% !important; */
    position: absolute;
    left: -15px;
    transform: none !important;
    z-index: 10;
    height: auto !important;
    width: 93% !important;
    overflow: overlay;
  }
  .updatepopup {
    padding: 1rem;

    margin: 0 auto;

    width: 81% !important;
  }
  .outerPerson {
    display: block;
  }
  .removetd {
    display: none;
  }
  .imageflex {
    display: flex;
  }
  .doctorclint {
    width: 80%;
  }
  .innerdoctorclint {
    width: 100%;
  }
  .sidebar {
    backdrop-filter: blur(30px);
    /* background: 0% 0% no-repeat padding-box padding-box rgb(96 11 72) !important; */
  }
  .doctorcards {
    margin: 0 auto !important;
    margin-bottom: 5% !important;
  }
  .clintsignuup {
    width: 80% !important;
    display: block !important;
  }
  .innerclintsignup {
    width: 85% !important;
  }
  .mobilebooking {
    width: 85% !important;
  }
  .headerbooking {
    width: 100% !important;
  }
  .callanderandtime {
    grid-template-rows: 1fr 1fr !important;

    grid-template-columns: 1fr !important;
  }
  .innerheaderbooking {
    width: 100% !important;
  }
  .breaker {
    display: none;
  }
  .calnderin {
    display: none;
  }
  .mwrapper {
    display: block;
  }
  .loginimage {
    width: 100%;
  }
  .smart {
    text-align: center !important;
  }
  .overview {
    /* width: 360px; */
    overflow: hidden;
  }

  .menu {
    display: block;
    float: left;
  }

  .sidebar {
    display: none;
    width: 13rem !important;
  }

  .sidebar.show {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .res-calendar {
    /* width: 100% !important; */
    margin: 0 auto;
  }

  .clint_calander {
    left: 14px !important;
  }

  .expert-list {
    display: none;
  }

  .mwrapper .mleft {
    width: auto;
  }

  .greet {
    display: none;
    line-height: 30px !important;
  }

  .greet_p {
    font-family: "GilroyExtraBold";
    width: 100%;
    line-height: 30px !important;
  }
  .clander_clint1 {
    margin: auto;
    padding: 10%;
    margin-left: 0 !important;
  }
  .wrapper {
    /* width: 20.3rem !important; */
    margin-left: 1rem !important;
  }

  /* .mright {
    display: none;
  } */

  .measure {
    flex-direction: column;
  }

  .boxes {
    width: 20.3rem !important;
  }
  .clintdaher {
    display: flex;
    justify-content: space-between;
    padding: 3px 10%;
  }
  .innerclintdaher {
    padding: 0 !important;
    font-size: 16px !important;
  }
  .box {
    margin: 4px 10px !important;
  }
  .boxstyle {
    margin: 0 auto !important;
    /* padding: 10% !important; */
  }
  .size {
    display: flex;
  }
  .box {
    width: auto !important;
    display: flow-root;
  }
  .eqalboxmobile {
    width: 150px !important;
  }
  .innerbox {
    width: 140px !important;
    height: 100px !important;
  }

  .innerbox .svg-inline--fa {
    width: 50px !important;
    height: 50px !important;
  }

  .note-container {
    height: auto !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    width: auto !important;
  }

  /* .sort-container {
    width: 20rem !important;
  } */

  .symptom-container {
    /* width: 19rem !important; */
    overflow-x: hidden;
  }

  .symptom-container table {
    width: auto !important;
  }

  .list-container {
    width: auto !important;
  }

  .splang {
    display: none;
  }
  .newbs {
    width: auto !important;
  }
  .padselecrt {
    padding: 10px 5px !important;
  }
  .spplate {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .list-container select {
    margin: 0;
  }

  .pager {
    width: 19rem !important;
  }

  .symptom-container table tr {
    justify-content: space-between;
    display: flex !important;
  }

  .symptom-container table tr strong {
    font-size: 12px;
  }

  .payment-container .card-header {
    width: 18rem !important;
  }

  .payment-container .inner {
    width: 19rem !important;
  }

  .payment-container .inner-flex {
    display: flex;
    flex-direction: column;
  }

  .appointment-tabs .appointment-inner {
    display: flex !important;
    margin: auto;
    flex-direction: column;
  }
  .appointment-tabs .appointment-inner .aleft {
    display: flex;
    flex-direction: column;
  }

  .appointment-tabs .appointment-inner button {
    margin: 1rem 0rem;
  }

  .medical-calendar {
    width: 19rem !important;
  }

  .module-wrap {
    padding: 0px 0px !important;
  }

  .spflex {
    /* width: 20rem !important; */
    overflow-x: scroll;
    scrollbar-width: none;
    border-radius: 10px;
  }

  .carousel {
    width: 100% !important;
  }

  .search-form input {
    width: 19rem !important;
  }

  .upcoming-scan {
    width: 22.5rem;
  }

  .upcoming-scan * {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .upcoming-scan table tr {
    display: flex;
    flex-direction: column;
    width: 20rem !important;
  }

  .upcoming-scan table tr td {
    margin-top: 5px !important;
  }

  .upcoming-scan table tr td {
    width: 17rem !important;
  }

  .upcoming-scan .card {
    padding: 10px !important;
  }

  .appointment-card .react-tabs {
    font-size: 13px !important;
  }

  .react-tabs__tab-list {
    width: 19rem !important;
    overflow-x: scroll !important;
  }

  /* .react-tabs__tab-panel {
    width: 19rem !important;
  } */
  .react-calendar {
    margin: auto;
  }
  .react-tabs__tab-panel video {
    width: 19rem;
  }

  .react-tabs__tab-panel table {
    width: 19rem;
    overflow: scroll;
  }

  .table-wrapper {
    overflow: scroll;
  }

  /* .module-wrapper {
    width: 19rem !important;
  } */
  .inneractivity {
    display: block !important;
  }
  .activity {
    margin-bottom: 15% !important;
    padding: 6%;
    background: #ffffff1c 0% 0% no-repeat padding-box;
    /* border-bottom: 1px solid black !important; */
  }
  .activityUrl {
    margin-top: 5%;
    float: none !important;
  }
  .activity .aflex {
    flex-direction: column;
  }

  .activity .aflex-inner {
    padding-top: 1rem !important;
  }

  .medicine-tracker {
    width: 19rem;
    overflow: scroll;
  }

  .medicine-tracker table {
    width: 19rem;
    overflow-x: scroll !important;
  }

  .medicine-tracker table tr {
    font-size: 8px !important;
  }

  .medicine-tracker table tr td {
    font-size: 13px;
  }
  .medicine-module {
    width: 100% !important;
  }
  .medicine-module form {
    width: 95%;
    margin: auto;
  }

  /* .medicine-module {
    width: 22rem !important;
  } */

  .medical-edit-form {
    /* background: 0% 0% no-repeat padding-box padding-box rgb(83 14 115) !important; */
    right: 1rem !important;
    margin: 10px auto;
    width: 19rem !important;
  }

  .personal-edit-form {
    /* background: 0% 0% no-repeat padding-box padding-box rgb(83 14 115) !important; */
    right: 1rem !important;
    margin: 10px auto;
    width: 19rem !important;
  }

  .home-chart {
    width: 21rem !important;
    margin: 10px !important;
  }

  /*===== Doctor =====  */

  .counter-collect {
    height: 20%;
    width: auto !important;
    display: flex;
    flex-direction: column;
  }

  .counter-card {
    width: 18.3rem !important;
  }

  .appointment-card {
    padding: 10px !important;
    overflow: scroll !important;
    /* width: 19rem !important; */
  }

  .all-clients-table {
    /* width: 21rem !important; */
    padding: 10px;
  }

  .all-clients-table hr {
    width: 20rem !important;
  }

  .all-clients-table .table-cover {
    width: 93% !important;
    overflow-x: scroll !important;
  }

  .all-clients-table p {
    width: 21.5rem !important;
  }

  /*=====  doctor profile =======*/

  .doctor-profile {
    width: 19rem !important;
    margin: 15px;
  }

  .doctor-profile table {
    width: 19rem !important;
  }

  .doctor-profile table tr {
    display: flex;
    flex-direction: column;
  }

  /*= ==== from admin   ==*/
  .doctor-absolute-profile {
    position: absolute !important;
    right: 0px !important;
    left: 0px !important;
    top: 5.5rem !important;
  }

  /*========= cs teams =========*/
  .search {
    width: 18.5rem !important;
  }

  .search input {
    width: 18.9rem !important;
  }

  .client-table p {
    width: 18.5rem !important;
  }

  .client-table {
    width: 21rem !important;
  }

  .client-table .table-cover {
    width: 21rem !important;
    overflow-x: scroll;
  }

  /* ========== hospital manager ============ */

  .over-cover {
    flex-direction: column;
  }

  .clients {
    width: 18.5rem !important;
  }

  .hospital {
    width: 18.5rem !important;
  }

  /* ===============  learning module ================== */

  .learning-form {
    overflow: hidden;
  }

  .learning-table-form tr td {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 80%;
    margin: auto;
  }

  .learning-table-form tr td input {
    width: 200px;
  }

  .learning-table-form tr td button {
    width: 200px;
  }

  /*======== Shedule appointments  ============*/
  .reshedule-sepr {
    flex-direction: column;
  }

  .shedule-card {
    left: 0px !important;
    width: 340px !important;
    margin-left: 10px;
  }

  /* ======= slots  ======*/
  .shedule-card .slott {
    width: 300px !important;
  }
  .reshedule-sepr .calendar-cover {
    width: 300px !important;
    margin: auto;
  }

  /* login and signup css */
  .signup-form {
    flex-direction: column !important;
  }

  .signup-form .form-input-flex {
    flex-direction: column;
    overflow: hidden !important;
  }

  .signup-form .form-input-flex input {
    width: 100% !important;
  }

  .signup-form form {
    width: 100% !important;
  }
  .formobile {
    width: 100% !important;
    overflow-x: scroll !important;
  }
  .margin-15 {
    width: auto !important;
    margin: 15px 15px !important;
  }

  .dailytabmobile {
    display: block !important;
    transform: none !important;
    margin: 5px 10px !important;
    width: 84% !important;
    left: 0 !important;
  }
}

@media screen and (width: 375px) {
  .menu {
    display: block;
  }

  .sidebar {
    display: none;
    width: 13rem !important;
  }

  .sidebar.show {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .res-calendar {
    display: none;
  }

  .expert-list {
    display: none;
  }

  .mwrapper .mleft {
    width: auto;
  }

  .greet {
    font-family: "GilroyExtraBold";
    line-height: 30px !important;
  }

  .wrapper {
    width: 20.3rem !important;
    margin-left: 1rem !important;
  }

  .mright {
    display: none;
  }

  .measure {
    flex-direction: column;
  }

  .boxes {
    width: 20.3rem !important;
  }
  .box {
    margin: 4px 10px !important;
  }

  .box {
    width: auto;
  }

  .innerbox {
    width: 140px !important;
    height: 100px !important;
  }

  .innerbox .svg-inline--fa {
    width: 50px !important;
    height: 50px !important;
  }

  .note-container {
    width: 19rem !important;
  }

  .symptom-container {
    width: 19rem !important;
    overflow-x: hidden;
  }

  .symptom-container table {
    width: auto !important;
  }

  .list-container {
    width: auto !important;
  }

  .splang {
    display: none;
  }

  .spplate {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .list-container select {
    margin: 0;
  }

  .pager {
    width: 19rem !important;
  }

  .symptom-container table tr {
    display: flex !important;
    flex-direction: column !important;
  }

  .medical-calendar {
    width: 19rem !important;
  }

  .symptom-container table tr {
    justify-content: space-between;
    display: flex !important;
  }

  .symptom-container table tr strong {
    font-size: 12px;
  }

  .react-tabs__tab-list {
    width: 22rem !important;
  }

  .module-wrap {
    padding: 0px 0px !important;
  }

  .spflex {
    width: 20rem !important;
    overflow-x: scroll;
    scrollbar-width: none;
    border-radius: 10px;
  }

  .carousel {
    width: 100% !important;
  }

  .search-form input {
    width: 19rem !important;
  }

  .upcoming-scan {
    width: 22.5rem;
  }

  .upcoming-scan * {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .upcoming-scan table tr {
    display: flex;
    flex-direction: column;
    width: 20rem !important;
  }

  .medicine-tracker table tr {
    font-size: 8px !important;
  }

  .medicine-tracker table tr td {
    font-size: 13px;
    white-space: nowrap;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .upcoming-scan table tr td {
    margin-top: 5px !important;
  }

  .upcoming-scan table tr td {
    width: 17rem !important;
  }

  .upcoming-scan .card {
    padding: 10px !important;
  }

  .react-tabs__tab-list {
    width: 19rem !important;
  }

  .payment-container .card-header {
    width: 21rem !important;
  }

  .payment-container .inner {
    width: 22rem !important;
  }

  .appointment-tabs .appointment-inner {
    display: flex !important;
    flex-direction: column;
  }
  .appointment-tabs .appointment-inner .aleft {
    display: flex;
    flex-direction: column;
  }
  .appointment-tabs .appointment-inner button {
    margin: 1rem 0rem;
  }

  .react-tabs__tab-panel {
    width: 19rem !important;
  }

  .react-tabs__tab-panel video {
    width: 19rem;
  }

  .react-tabs__tab-panel table {
    width: 19rem;
    overflow: scroll;
  }

  .table-wrapper {
    overflow: scroll;
  }

  .module-wrapper {
    width: 19rem !important;
  }

  .activity .aflex {
    flex-direction: column;
  }

  .activity .aflex-inner {
    padding-top: 1rem !important;
  }

  .medicine-tracker {
    width: 19rem;
    overflow: scroll;
  }

  .medicine-tracker table {
    width: 19rem;
    overflow-x: scroll !important;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .personal-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  /*===== Doctor =====  */

  .counter-collect {
    width: 18rem !important;
    display: flex;
    flex-direction: column;
  }

  .home-chart {
    width: 18rem !important;
  }

  .doctor-profile {
    width: 21rem !important;
    margin: 15px;
  }
}

@media screen and (width: 414px) {
  .menu {
    display: block;
  }

  .sidebar {
    display: none;
    width: 13rem !important;
  }

  .sidebar.show {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .res-calendar {
    display: none;
  }

  .expert-list {
    display: none;
  }

  .mwrapper .mleft {
    width: auto;
  }

  .greet {
    font-family: "GilroyExtraBold";
    line-height: 30px !important;
  }

  .wrapper {
    width: 23.7rem !important;
    margin-left: 1rem !important;
  }

  .mright {
    display: none;
  }

  .measure {
    flex-direction: column;
  }

  .boxes {
    width: 24.5rem !important;
  }
  .box {
    margin: 4px 10px !important;
  }

  .box {
    width: auto;
  }

  .innerbox {
    width: 165px !important;
  }

  .note-container {
    width: 22rem !important;
  }

  .symptom-container {
    width: 22rem !important;
    overflow-x: hidden;
  }

  .react-tabs__tab-list {
    width: 22rem !important;
  }

  .react-tabs__tab-panel {
    width: 22rem !important;
  }

  .react-tabs__tab-panel video {
    width: 22rem;
  }

  .react-tabs__tab-panel table {
    width: 22rem;
  }

  .medicine-tracker {
    width: 22rem;
  }

  .medicine-tracker table {
    width: 24rem;
    overflow-x: scroll !important;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .personal-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  /*===== Doctor =====  */

  .counter-collect {
    width: 22rem !important;
    display: flex;
    flex-direction: column;
  }

  .home-chart {
    width: 24.2rem !important;
  }

  .counter-card {
    width: 21.6rem !important;
  }

  .sort-container {
    width: 23.3rem !important;
  }

  .all-clients-table {
    width: 24.5rem !important;
  }

  .all-clients-table hr {
    width: 24rem !important;
  }

  .all-clients-table .table-cover {
    width: 24.5rem !important;
    overflow: scroll;
  }

  .all-clients-table p {
    width: 23.5rem !important;
  }

  .doctor-profile {
    width: 22rem !important;
    margin: 15px;
  }

  /*========= cs teams =========*/
  .search {
    width: 21.8rem !important;
  }

  .search input {
    width: 21.9rem !important;
  }

  .client-table p {
    width: 24.5rem !important;
  }

  .client-table {
    width: 24.5rem !important;
  }

  .client-table .table-cover {
    width: 24.5rem !important;
    overflow-x: scroll;
  }
}

@media screen and (width: 768px) {
  .menu {
    display: block;
  }

  .sidebar {
    display: none;
    width: 13rem !important;
  }

  .sidebar.show {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .res-calendar {
    display: none;
  }

  .expert-list {
    display: none;
  }

  .mwrapper .mleft {
    width: auto;
  }

  .greet {
    font-family: "GilroyExtraBold";
    line-height: 30px !important;
  }

  .wrapper {
    width: 46rem !important;
    margin-left: 1rem !important;
  }

  .mright {
    display: none;
  }

  .notify {
    top: 4% !important;
    right: 8% !important;
  }

  .profile {
    right: 2% !important;
    top: 4% !important;
  }

  .payment-container .card-header {
    width: 43rem !important;
  }

  .payment-container .inner {
    width: 44rem !important;
  }

  .top-header {
    padding: 2% 2% !important;
  }

  .measure {
    flex-direction: column;
  }

  .boxes {
    width: 47rem !important;
  }
  .box {
    margin: 4px 10px !important;
    width: auto !important;
  }

  .innerbox {
    width: 222px !important;
  }

  .note-container {
    width: 44rem !important;
  }

  .module-wrapper {
    width: 44rem !important;
  }

  .bouter {
    margin-top: 1rem;
  }

  .bbox {
    width: 24rem !important;
    margin-top: 1rem;
  }

  .react-tabs__tab-list {
    width: 44rem !important;
  }

  .react-tabs__tab-panel {
    width: 44rem !important;
  }

  .react-tabs__tab-panel video {
    width: 44rem !important;
  }

  .react-tabs__tab-panel table {
    width: 44rem;
  }

  .medicine-tracker {
    width: 44rem;
  }

  .medicine-tracker table {
    width: 44rem;
    overflow-x: scroll !important;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .personal-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }
}

@media screen and (width: 800px) {
  .menu {
    display: block;
  }

  .sidebar {
    display: none;
    width: 13rem !important;
  }

  .sidebar.show {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  .res-calendar {
    display: none;
  }

  .expert-list {
    display: none;
  }

  .mwrapper .mleft {
    width: auto;
  }

  .greet {
    font-family: "GilroyExtraBold";
    line-height: 30px !important;
  }

  .wrapper {
    width: 46rem !important;
    margin-left: 1rem !important;
  }

  .mright {
    display: none;
  }

  .notify {
    top: 4% !important;
    right: 8% !important;
  }

  .profile {
    right: 2% !important;
    top: 4% !important;
  }

  .top-header {
    padding: 2% 2% !important;
  }

  .measure {
    flex-direction: column;
  }

  .boxes {
    width: 47rem !important;
  }
  .box {
    margin: 4px 10px !important;
  }

  .box {
    width: auto !important;
  }

  .innerbox {
    width: 232px !important;
  }
  .note-container {
    width: 46rem !important;
  }

  .bouter {
    margin-top: 1rem;
  }

  .bbox {
    width: 24rem !important;
    margin-top: 1rem;
  }

  .module-wrapper {
    width: 46rem !important;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .react-tabs__tab-list {
    width: 46rem !important;
  }

  .react-tabs__tab-panel {
    width: 46rem !important;
  }

  .react-tabs__tab-panel video {
    width: 46rem;
  }

  .react-tabs__tab-panel table {
    width: 46rem;
  }

  .payment-container .card-header {
    width: 45rem !important;
  }

  .payment-container .inner {
    width: 46rem !important;
  }

  .medicine-tracker {
    width: 46rem;
  }

  .medicine-tracker table {
    width: 46rem;
    overflow-x: scroll !important;
  }

  .medical-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .personal-edit-form {
    right: 0rem !important;
    margin: 10px auto;
    width: 24rem !important;
  }

  .counter-collect {
    width: 50rem !important;
    display: flex;
    justify-content: space-between;
  }

  .all-clients-table {
    overflow-x: hidden !important;
  }

  /*======== doctor profile =========*/

  .doctor-profile {
    width: 100% !important;
    margin: 15px;
  }

  /*= ==== from admin   ==*/
  .doctor-absolute-profile {
    position: absolute !important;
    right: 0px !important;
    left: 0px !important;
    top: 8rem !important;
  }
}
.vac-btn{
  background-color:#ffffff2d ;
  width: 240px;
  margin:auto;
  padding: 24px;
  border-radius: 30px;
  cursor: pointer;
  border:2px solid #ffffff3d
  
}
.vac-btn:hover{
  background-color: white;
  color: black;
  transition: .2s;
}