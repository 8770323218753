.accordion {
  max-width: 100%;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  font-size: 16px;
  flex-direction: row;
  margin-bottom: 5px;
  justify-content: space-between;
  cursor: pointer;
  background: #6a166b;
  /* background-color: #21aeca; */
}
/* 
.accordion-title:hover {
  background-color: #3ab4cc;
} */

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  font-size: 16px;
}
table {
  width: 100% !important;
}
