#myinput {
  background-image: linear-gradient(
    to right,
    #008000 0%,
    #1c9e52 25%,
    yellow 50%,
    orange 75%,
    red 100%
  );
  border: solid 1px #82cfd0;
  border-radius: 8px;
  height: 7px;
  width: 356px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
