@media screen and (max-width: 600px) {
  .login-wrapper {
    flex-direction: column !important;
    width: 80% !important;
  }

  .form-wrapper {
    width: 100% !important;
    padding: 0 !important;
  }
  .form-wrapper form {
    padding: 2% !important;
  }
  .form-wrapper {
    margin-top: 30px;
  }
}
