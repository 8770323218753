.text-center {
  text-align: center;
}
span#SPAN_39 {
  margin-left: 26%;
}
div#DIV_84 {
  line-height: 1.5;
}
div#DIV_84d {
  font-size: 12px;
}
span#SPAN_48 {
  margin-left: -5%;
}
span#SPAN_45 {
  margin: auto;
}
#TD_80 div {
  margin: auto;
}
#TD_123 div {
  margin: auto;
}

span#SPAN_42 {
  margin: auto;
}
span#SPAN_48 {
  margin: auto;
}
div#DIV_90 {
  margin: auto;
}
div#DIV_94 {
  margin: auto;
}
div#DIV_128 {
  margin: auto;
}
/* #TD_80 button {
  /* width: 50% !important; */
/* } */
/* #TD_123 button {
  width: 50% !important;
} */
.arrow {
  position: absolute;
  border-radius: 3px;
  font-size: 76px;
  top: 35%;

  color: #fff;
}

.arrow:hover {
  color: #fff;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

#DIV_1 {
  block-size: 1429.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 1429.6px;
  inline-size: 1519.2px;
  min-block-size: 745.6px;
  min-height: 745.6px;
  perspective-origin: 759.6px 714.8px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 759.6px 714.8px;
  unicode-bidi: isolate;
  width: 1519.2px;
  border: 0px none rgb(51, 51, 51);
  flex-flow: column nowrap;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_1*/

#DIV_1:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_1:after*/

#DIV_1:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_1:before*/

#DIV_2 {
  block-size: 90px;
  /* border-block-end-color: rgb(230, 230, 230); */
  border-block-end-style: solid;
  border-block-end-width: 1px;
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 0px;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 90px;
  inline-size: 1519.2px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 759.6px 45.5px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 759.6px 45.5px;
  width: 1519.2px;
  /* background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box */
  /* border-box; */
  border-top: 0px none rgb(51, 51, 51);
  border-right: 0px none rgb(51, 51, 51);
  /* border-bottom: 1px solid rgb(230, 230, 230); */
  border-left: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_2*/

#DIV_2:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_2:after*/

#DIV_2:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_2:before*/

#A_3 {
  block-size: 90px;
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  display: inline-block;
  fill: rgb(229, 9, 20);
  height: 90px;
  inline-size: 167px;
  margin-inline-start: 45.575px;
  perspective-origin: 83.5px 45px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  transform-origin: 83.5px 45px;
  vertical-align: middle;
  width: 167px;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0px 0px 0px 45.575px;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_3*/

#A_3:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_3:after*/

#A_3:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_3:before*/

#svg_4 {
  block-size: 45px;
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  height: 45px;
  inline-size: 167px;
  perspective-origin: 83.5px 22.5px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  transform-origin: 83.5px 22.5px;
  vertical-align: middle;
  width: 167px;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
  overflow: hidden;
} /*#svg_4*/

#svg_4:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#svg_4:after*/

#svg_4:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#svg_4:before*/

#g_5 {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#g_5*/

#g_5:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#g_5:after*/

#g_5:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#g_5:before*/

#path_6 {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  d: path(
    "M 105.062 14.2806 L 110.999 30 C 109.249 29.7497 107.5 29.4367 105.718 29.1555 L 102.374 20.4686 L 98.9371 28.4375 C 97.25 28.1563 95.5928 28.0617 93.9057 27.8433 L 99.9372 14.0932 L 94.4681 -5.68434e-14 L 99.5313 -5.68434e-14 L 102.593 7.87422 L 105.875 -5.68434e-14 L 110.999 -5.68434e-14 L 105.062 14.2806 Z M 90.4687 -5.68434e-14 L 85.875 -5.68434e-14 L 85.875 27.25 C 87.3746 27.3437 88.9371 27.4056 90.4687 27.593 L 90.4687 -5.68434e-14 Z M 81.9055 26.9369 C 77.7186 26.6557 73.5308 26.4064 69.2502 26.3117 L 69.2502 -5.68434e-14 L 73.9366 -5.68434e-14 L 73.9366 21.8746 C 76.6248 21.9374 79.312 22.1558 81.9055 22.2804 L 81.9055 26.9369 Z M 64.2497 10.6561 L 64.2497 15.3435 L 57.8442 15.3435 L 57.8442 25.9996 L 53.2187 25.9996 L 53.2187 -5.68434e-14 L 66.3436 -5.68434e-14 L 66.3436 4.68741 L 57.8442 4.68741 L 57.8442 10.6561 L 64.2497 10.6561 Z M 45.3435 4.68741 L 45.3435 26.2499 C 43.781 26.2499 42.1876 26.2499 40.6561 26.3117 L 40.6561 4.68741 L 35.8122 4.68741 L 35.8122 -5.68434e-14 L 50.2184 -5.68434e-14 L 50.2184 4.68741 L 45.3435 4.68741 Z M 30.7498 15.5928 C 28.6878 15.5928 26.2499 15.5928 24.5 15.6875 L 24.5 22.6563 C 27.25 22.4679 30 22.2495 32.781 22.1558 L 32.781 26.6557 L 19.8125 27.6877 L 19.8125 -5.68434e-14 L 32.781 -5.68434e-14 L 32.781 4.68741 L 24.5 4.68741 L 24.5 10.9992 C 26.3127 10.9992 29.0936 10.9054 30.7498 10.9054 L 30.7498 15.5928 Z M 4.78114 12.9684 L 4.78114 29.343 C 3.09401 29.5314 1.5934 29.7497 0 30 L 0 -5.68434e-14 L 4.46902 -5.68434e-14 L 10.5624 17.0316 L 10.5624 -5.68434e-14 L 15.2498 -5.68434e-14 L 15.2498 28.0617 C 13.5936 28.3438 11.9065 28.4375 10.1247 28.6868 L 4.78114 12.9684 Z"
  );
  fill: rgb(229, 9, 20);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#path_6*/

#path_6:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#path_6:after*/

#path_6:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#path_6:before*/

#SPAN_7 {
  block-size: 90px;
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  bottom: 9999px;
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  display: block;
  fill: rgb(229, 9, 20);
  height: 90px;
  inline-size: 48.05px;
  inset-block-end: 9999px;
  inset-block-start: -9999px;
  inset-inline-end: 11470.2px;
  inset-inline-start: -9999px;
  left: -9999px;
  perspective-origin: 24.025px 45px;
  position: absolute;
  right: 11470.2px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  top: -9999px;
  transform-origin: 24.025px 45px;
  width: 48.05px;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#SPAN_7*/

#SPAN_7:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#SPAN_7:after*/

#SPAN_7:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  cursor: pointer;
  fill: rgb(229, 9, 20);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 16px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#SPAN_7:before*/

#A_8 {
  block-size: 90px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  float: right;
  height: 90px;
  inline-size: 60.9375px;
  margin-inline-end: 45.575px;
  margin-inline-start: 45.575px;
  perspective-origin: 30.4625px 45px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 30.4688px 45px;
  width: 60.9375px;
  border: 0px none rgb(51, 51, 51);
  font: 700 19px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0px 45.575px;
  outline: rgb(51, 51, 51) none 0px;
} /*#A_8*/

#A_8:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 19px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#A_8:after*/

#A_8:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 19px / 90px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#A_8:before*/

#DIV_9 {
  block-size: 1030.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  /* height: 1030.6px; */
  inline-size: 1519.2px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 95px;
  perspective-origin: 759.6px 562.8px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 759.6px 562.8px;
  width: 1519.2px;
  /* background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
    border-box; */
  border: 0px none rgb(51, 51, 51);
  flex: 1 1 auto;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  /* overflow: hidden; */
  padding: 0px 0px 95px;
} /*#DIV_9*/

#DIV_9:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_9:after*/

#DIV_9:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_9:before*/

#DIV_10 {
  block-size: 1015.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  /* height: fit-content; */
  inline-size: 978px;
  margin-block-end: 15px;
  margin-inline-end: 270.6px;
  margin-inline-start: 270.6px;
  max-inline-size: 978px;
  max-width: 978px;
  padding-block-end: 60px;
  padding-block-start: 20px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 489px 507.8px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 489px 507.8px;
  width: 978px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 270.6px 15px;
  outline: rgb(51, 51, 51) none 0px;
  /* overflow: hidden; */
  padding: 20px 16px 60px;
} /*#DIV_10*/

#DIV_10:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_10:after*/

#DIV_10:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_10:before*/

#DIV_11 {
  block-size: 847.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  /* height: fit-content; */
  /* height: 1219px; */
  inline-size: 946px;
  max-inline-size: 1024px;
  max-width: 1024px;
  perspective-origin: 473px 423.8px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 423.8px;
  width: 946px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  /* overflow: hidden; */
} /*#DIV_11*/

#DIV_11:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_11:after*/

#DIV_11:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_11:before*/

#DIV_12 {
  block-size: 168.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 168.6px;
  inline-size: 946px;
  perspective-origin: 473px 84.3px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 84.3px;
  width: 946px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_12*/

#DIV_12:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_12:after*/

#DIV_12:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_12:before*/

#DIV_13 {
  block-size: 80.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 80.6px;
  inline-size: 914px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 473px 40.3px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 40.3px;
  width: 914px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px 16px;
} /*#DIV_13*/

#DIV_13:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_13:after*/

#DIV_13:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_13:before*/

#DIV_14 {
  block-size: 80.6px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: inline-block;
  height: 80.6px;
  inline-size: 519.938px;
  perspective-origin: 259.962px 40.3px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 259.969px 40.3px;
  width: 519.938px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_14*/

#DIV_14:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_14:after*/

#DIV_14:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_14:before*/

#SPAN_15 {
  block-size: 27px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 27px;
  inline-size: 519.938px;
  perspective-origin: 259.962px 13.5px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 259.969px 13.5px;
  width: 519.938px;
  border: 0px none rgb(51, 51, 51);
  font: 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_15*/

#SPAN_15:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_15:after*/

#SPAN_15:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_15:before*/

#B_16,
#B_17 {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(51, 51, 51);
  font: 700 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#B_16, #B_17*/

#B_16:after,
#B_17:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#B_16:after, #B_17:after*/

#B_16:before,
#B_17:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 13px / 27px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#B_16:before, #B_17:before*/

#H1_18 {
  block-size: 40.8px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: inline-block;
  height: 40.8px;
  inline-size: 519.938px;
  margin-block-end: 12.8px;
  margin-block-start: 0px;
  perspective-origin: 259.962px 20.4px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 259.969px 20.4px;
  width: 519.938px;
  border: 0px none rgb(51, 51, 51);
  font: 700 32px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 12.8px;
  outline: rgb(51, 51, 51) none 0px;
} /*#H1_18*/

#H1_18:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 32px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#H1_18:after*/

#H1_18:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 700 32px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#H1_18:before*/

#DIV_19 {
  block-size: 84px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 84px;
  inline-size: 914px;
  margin-block-end: 20px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 473px 42px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 42px;
  width: 914px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 20px;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px 16px;
} /*#DIV_19*/

#DIV_19:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_19:after*/

#DIV_19:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_19:before*/

#UL_20 {
  block-size: 84px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 84px;
  inline-size: 914px;
  justify-content: space-between;
  margin-block-end: 20px;
  margin-block-start: 4px;
  order: -1;
  padding-inline-start: 0px;
  perspective-origin: 457px 42px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 457px 42px;
  width: 914px;
  border: 0px none rgb(51, 51, 51);
  flex-flow: column nowrap;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 4px 0px 20px;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px;
} /*#UL_20*/

#UL_20:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#UL_20:after*/

#UL_20:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#UL_20:before*/

#LI_21 {
  align-items: flex-start;
  block-size: 24px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 24px;
  inline-size: 914px;
  justify-content: flex-start;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 457px 12px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 457px 12px;
  width: 914px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_21*/

#LI_21:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_21:after*/

#LI_21:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_21:before*/

#svg_22,
#svg_26,
#svg_30 {
  align-self: flex-start;
  block-size: 24px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  display: block;
  fill: none;
  height: 24px;
  inline-size: 24px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 12px 12px;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 12px 12px;
  width: 24px;
  border: 0px none rgb(229, 9, 20);
  flex: 0 0 auto;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
  overflow: hidden;
} /*#svg_22, #svg_26, #svg_30*/

#svg_22:after,
#svg_26:after,
#svg_30:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: none;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#svg_22:after, #svg_26:after, #svg_30:after*/

#svg_22:before,
#svg_26:before,
#svg_30:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: none;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#svg_22:before, #svg_26:before, #svg_30:before*/

#path_23,
#path_27,
#path_31 {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  d: path(
    "M 8.68239 19.7312 L 23.6824 5.73115 L 22.3178 4.26904 L 8.02404 17.6098 L 2.70718 12.293 L 1.29297 13.7072 L 7.29297 19.7072 C 7.67401 20.0882 8.28845 20.0988 8.68239 19.7312 Z"
  );
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(229, 9, 20);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_23, #path_27, #path_31*/

#path_23:after,
#path_27:after,
#path_31:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_23:after, #path_27:after, #path_31:after*/

#path_23:before,
#path_27:before,
#path_31:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: left;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_23:before, #path_27:before, #path_31:before*/

#SPAN_24 {
  block-size: 23.2px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 23.2px;
  inline-size: 231.45px;
  margin-inline-start: 10px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 115.725px 11.6px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 115.725px 11.6px;
  width: 231.45px;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 0px 10px;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_24*/

#SPAN_24:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_24:after*/

#SPAN_24:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_24:before*/

#LI_25,
#LI_29 {
  align-items: flex-start;
  block-size: 24px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 24px;
  inline-size: 914px;
  justify-content: flex-start;
  margin-block-start: 6px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 457px 12px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 457px 12px;
  width: 914px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 6px 0px 0px;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_25, #LI_29*/

#LI_25:after,
#LI_29:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_25:after, #LI_29:after*/

#LI_25:before,
#LI_29:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LI_25:before, #LI_29:before*/

#SPAN_28 {
  block-size: 23.2px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 23.2px;
  inline-size: 253.7px;
  margin-inline-start: 10px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 126.85px 11.6px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 126.85px 11.6px;
  width: 253.7px;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 0px 10px;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_28*/

#SPAN_28:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_28:after*/

#SPAN_28:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_28:before*/

#SPAN_32 {
  block-size: 23.2px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 23.2px;
  inline-size: 297.062px;
  margin-inline-start: 10px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 148.525px 11.6px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 148.531px 11.6px;
  width: 297.062px;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 0px 10px;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_32*/

#SPAN_32:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_32:after*/

#SPAN_32:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 18px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_32:before*/

#DIV_33 {
  block-size: 659px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 659px;
  inline-size: 946px;
  perspective-origin: 473px 329.5px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 329.5px;
  width: 946px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_33*/

#DIV_33:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_33:after*/

#DIV_33:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_33:before*/

#DIV_34 {
  align-items: flex-end;
  block-size: 144px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 144px;
  inline-size: 946px;
  perspective-origin: 473px 72px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 72px;
  width: 946px;
  border: 0px none rgb(51, 51, 51);
  flex-flow: column nowrap;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_34*/

#DIV_34:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_34:after*/

#DIV_34:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_34:before*/

#DIV_35 {
  block-size: 144px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 144px;
  inline-size: 614.9px;
  justify-content: center;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  order: 1;
  perspective-origin: 307.45px 72px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 307.45px 72px;
  width: 614.9px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_35*/

#DIV_35:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_35:after*/

#DIV_35:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_35:before*/

#SPAN_36 {
  block-size: 1px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 445px;
  caret-color: rgb(51, 51, 51);
  clip: rect(1px, 1px, 1px, 1px);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 1px;
  inline-size: 1px;
  inset-block-end: 445px;
  inset-block-start: 299.6px;
  inset-inline-end: 593.55px;
  inset-inline-start: 924.65px;
  left: 924.65px;
  perspective-origin: 0.5px 0.5px;
  position: absolute;
  right: 593.55px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 299.6px;
  transform-origin: 0.5px 0.5px;
  width: 1px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  overflow: hidden;
} /*#SPAN_36*/

#SPAN_36:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_36:after*/

#SPAN_36:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#SPAN_36:before*/

#LABEL_37,
#LABEL_40,
#LABEL_43,
#LABEL_46 {
  block-size: 144px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 144px;
  inline-size: 153.725px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 12px;
  perspective-origin: 76.8625px 72px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 76.8625px 72px;
  width: 153.725px;
  border: 0px none rgb(51, 51, 51);
  flex: 1 1 auto;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  padding: 12px 0px;
} /*#LABEL_37, #LABEL_40, #LABEL_43, #LABEL_46*/

#LABEL_37:after,
#LABEL_40:after,
#LABEL_43:after,
#LABEL_46:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LABEL_37:after, #LABEL_40:after, #LABEL_43:after, #LABEL_46:after*/

#LABEL_37:before,
#LABEL_40:before,
#LABEL_43:before,
#LABEL_46:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LABEL_37:before, #LABEL_40:before, #LABEL_43:before, #LABEL_46:before*/

#INPUT_38,
#INPUT_41,
#INPUT_44,
#INPUT_47 {
  appearance: none;
  background-position: 0px 0px;
  block-size: 120px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-end-style: none;
  border-block-end-width: 0px;
  border-block-start-color: rgb(51, 51, 51);
  border-block-start-style: none;
  border-block-start-width: 0px;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-end-style: none;
  border-inline-end-width: 0px;
  border-inline-start-color: rgb(51, 51, 51);
  border-inline-start-style: none;
  border-inline-start-width: 0px;
  bottom: -48px;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  cursor: default;
  display: block;
  height: 120px;
  inline-size: 120px;
  inset-block-end: -48px;
  inset-block-start: 72px;
  inset-inline-end: -43.1375px;
  inset-inline-start: 76.8625px;
  left: 76.8625px;
  opacity: 0;
  padding-block-end: 0px;
  padding-block-start: 0px;
  padding-inline-end: 0px;
  padding-inline-start: 0px;
  perspective-origin: 60px 60px;
  position: absolute;
  right: -43.1375px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 72px;
  transform: matrix(1, 0, 0, 1, -60, -60);
  transform-origin: 60px 60px;
  width: 120px;
  z-index: -1;
  background: rgba(0, 0, 0, 0) none repeat scroll 0px 0px / auto padding-box
    border-box;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px;
} /*#INPUT_38, #INPUT_41, #INPUT_44, #INPUT_47*/

#INPUT_38:after,
#INPUT_41:after,
#INPUT_44:after,
#INPUT_47:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  cursor: default;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#INPUT_38:after, #INPUT_41:after, #INPUT_44:after, #INPUT_47:after*/

#INPUT_38:before,
#INPUT_41:before,
#INPUT_44:before,
#INPUT_47:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  cursor: default;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#INPUT_38:before, #INPUT_41:before, #INPUT_44:before, #INPUT_47:before*/

#SPAN_39,
#SPAN_45 {
  align-items: center;
  block-size: 120px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-end-end-radius: 2px;
  border-end-start-radius: 2px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-start-end-radius: 2px;
  border-start-start-radius: 2px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: flex;
  height: 120px;
  inline-size: 120px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  justify-content: center;
  left: 0px;
  opacity: 1;
  padding-block-end: 1px;
  padding-block-start: 1px;
  padding-inline-end: 1px;
  padding-inline-start: 1px;
  perspective-origin: 60px 60px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 60px 60px;
  user-select: none;
  width: 120px;
  background: rgb(229, 9, 20) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  border-radius: 2px;
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  padding: 1px;
} /*#SPAN_39, #SPAN_45*/

#SPAN_39:after,
#SPAN_45:after {
  block-size: 0px;
  border-block-end-color: rgba(0, 0, 0, 0);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 9, 20);
  border-block-start-style: solid;
  border-inline-end-color: rgba(0, 0, 0, 0);
  border-inline-end-style: solid;
  border-inline-start-color: rgba(0, 0, 0, 0);
  border-inline-start-style: solid;
  bottom: 0px;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  /* content: '""'; */
  cursor: default;
  display: block;
  height: 0px;
  inline-size: 0px;
  inset-block-end: 0px;
  inset-block-start: 120px;
  inset-inline-end: 60px;
  inset-inline-start: 60px;
  left: 60px;
  perspective-origin: 0px 0px;
  position: absolute;
  right: 60px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 120px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 0px 0px;
  user-select: none;
  width: 0px;
  border-top: 0px solid rgb(229, 9, 20);
  border-right: 0px solid rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0);
  border-left: 0px solid rgba(0, 0, 0, 0);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_39:after, #SPAN_45:after*/

#SPAN_39:before,
#SPAN_45:before {
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: block;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  user-select: none;
  border: 0px none rgb(255, 255, 255);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_39:before, #SPAN_45:before*/

#SPAN_42 {
  align-items: center;
  block-size: 120px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-end-end-radius: 2px;
  border-end-start-radius: 2px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-start-end-radius: 2px;
  border-start-start-radius: 2px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: flex;
  height: 120px;
  inline-size: 120px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  justify-content: center;
  left: 0px;
  padding-block-end: 1px;
  padding-block-start: 1px;
  padding-inline-end: 1px;
  padding-inline-start: 1px;
  perspective-origin: 60px 60px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 60px 60px;
  user-select: none;
  width: 120px;
  background: rgb(229, 9, 20) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  border-radius: 2px;
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  padding: 1px;
} /*#SPAN_42*/

#SPAN_42:after {
  block-size: 0px;
  border-block-end-color: rgba(0, 0, 0, 0);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 9, 20);
  border-block-start-style: solid;
  border-block-start-width: 10px;
  border-inline-end-color: rgba(0, 0, 0, 0);
  border-inline-end-style: solid;
  border-inline-end-width: 10px;
  border-inline-start-color: rgba(0, 0, 0, 0);
  border-inline-start-style: solid;
  border-inline-start-width: 10px;
  bottom: -10px;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  /* content: '""'; */
  cursor: default;
  display: block;
  height: 0px;
  inline-size: 0px;
  inset-block-end: -10px;
  inset-block-start: 120px;
  inset-inline-end: 40px;
  inset-inline-start: 60px;
  left: 60px;
  perspective-origin: 10px 5px;
  position: absolute;
  right: 40px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 120px;
  transform: matrix(1, 0, 0, 1, -10, 0);
  transform-origin: 10px 5px;
  user-select: none;
  width: 0px;
  border-top: 10px solid rgb(229, 9, 20);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0);
  border-left: 10px solid rgba(0, 0, 0, 0);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_42:after*/

#SPAN_42:before {
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: block;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  user-select: none;
  border: 0px none rgb(255, 255, 255);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_42:before*/

#SPAN_48 {
  align-items: center;
  block-size: 120px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-end-end-radius: 2px;
  border-end-start-radius: 2px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-start-end-radius: 2px;
  border-start-start-radius: 2px;
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: flex;
  height: 120px;
  inline-size: 120px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  justify-content: center;
  left: 0px;
  opacity: 1;
  padding-block-end: 1px;
  padding-block-start: 1px;
  padding-inline-end: 1px;
  padding-inline-start: 1px;
  perspective-origin: 60px 60px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 60px 60px;
  user-select: none;
  width: 120px;
  background: rgb(229, 9, 20) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  border-radius: 2px;
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  padding: 1px;
} /*#SPAN_48*/

#SPAN_48:after {
  block-size: 0px;
  border-block-end-color: rgba(0, 0, 0, 0);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 9, 20);
  border-block-start-style: solid;
  border-inline-end-color: rgba(0, 0, 0, 0);
  border-inline-end-style: solid;
  border-inline-start-color: rgba(0, 0, 0, 0);
  border-inline-start-style: solid;
  bottom: 0px;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  /* content: '""'; */
  cursor: default;
  display: block;
  height: 0px;
  inline-size: 0px;
  inset-block-end: 0px;
  inset-block-start: 120px;
  inset-inline-end: 60px;
  inset-inline-start: 60px;
  left: 60px;
  perspective-origin: 0px 0px;
  position: absolute;
  right: 60px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 120px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 0px 0px;
  user-select: none;
  width: 0px;
  border-top: 0px solid rgb(229, 9, 20);
  border-right: 0px solid rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0);
  border-left: 0px solid rgba(0, 0, 0, 0);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_48:after*/

#SPAN_48:before {
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: default;
  display: block;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  user-select: none;
  border: 0px none rgb(255, 255, 255);
  font: 700 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#SPAN_48:before*/

#TABLE_49 {
  block-size: 441px;
  border-collapse: collapse;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 441px;
  inline-size: 946px;
  padding-block-end: 10px;
  perspective-origin: 473px 220.5px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 220.5px;
  width: 946px;
  border-spacing: 0px 0px;
  flex-flow: column nowrap;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px 0px 10px;
} /*#TABLE_49*/

#TABLE_49:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  border-spacing: 0px 0px;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TABLE_49:after*/

#TABLE_49:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  border-spacing: 0px 0px;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TABLE_49:before*/

#CAPTION_50 {
  block-size: 1px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 301px;
  caret-color: rgb(51, 51, 51);
  clip: rect(1px, 1px, 1px, 1px);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 1px;
  inline-size: 1px;
  inset-block-end: 301px;
  inset-block-start: 443.6px;
  inset-inline-end: 1231.6px;
  inset-inline-start: 286.6px;
  left: 286.6px;
  perspective-origin: 0.5px 0.5px;
  position: absolute;
  right: 1231.6px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 443.6px;
  transform-origin: 0.5px 0.5px;
  width: 1px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  overflow: hidden;
} /*#CAPTION_50*/

#CAPTION_50:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#CAPTION_50:after*/

#CAPTION_50:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#CAPTION_50:before*/

#TBODY_51 {
  block-size: 431px;
  border-block-end-color: rgb(128, 128, 128);
  border-block-start-color: rgb(128, 128, 128);
  border-collapse: collapse;
  border-inline-end-color: rgb(128, 128, 128);
  border-inline-start-color: rgb(128, 128, 128);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 431px;
  inline-size: 946px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 473px 215.5px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 215.5px;
  width: 946px;
  border: 0px none rgb(128, 128, 128);
  flex-flow: column nowrap;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TBODY_51*/

#TBODY_51:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TBODY_51:after*/

#TBODY_51:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TBODY_51:before*/

#TR_52,
#TR_58,
#TR_68 {
  align-items: center;
  block-size: 60px;
  border-block-end-color: rgb(204, 204, 204);
  border-block-end-style: solid;
  border-block-end-width: 1px;
  border-block-start-color: rgb(128, 128, 128);
  border-collapse: collapse;
  border-inline-end-color: rgb(128, 128, 128);
  border-inline-start-color: rgb(128, 128, 128);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 60px;
  inline-size: 946px;
  min-block-size: 60px;
  min-height: 60px;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 473px 30.5px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 30.5px;
  vertical-align: middle;
  width: 946px;
  border-top: 0px none rgb(128, 128, 128);
  border-right: 0px none rgb(128, 128, 128);
  border-bottom: 1px solid rgb(204, 204, 204);
  border-left: 0px none rgb(128, 128, 128);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_52, #TR_58, #TR_68*/

#TR_52:after,
#TR_58:after,
#TR_68:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_52:after, #TR_58:after, #TR_68:after*/

#TR_52:before,
#TR_58:before,
#TR_68:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_52:before, #TR_58:before, #TR_68:before*/

#TD_53,
#TD_59,
#TD_69,
#TD_79 {
  block-size: 44px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  fill: rgb(51, 51, 51);
  height: 44px;
  inline-size: 331.1px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 12px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 165.55px 22px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 165.55px 22px;
  vertical-align: middle;
  width: 331.1px;
  border: 0px none rgb(51, 51, 51);
  flex: 0 0 auto;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  padding: 12px 16px;
} /*#TD_53, #TD_59, #TD_69, #TD_79*/

#TD_53:after,
#TD_59:after,
#TD_69:after,
#TD_79:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  fill: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TD_53:after, #TD_59:after, #TD_69:after, #TD_79:after*/

#TD_53:before,
#TD_59:before,
#TD_69:before,
#TD_79:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  fill: rgb(51, 51, 51);
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TD_53:before, #TD_59:before, #TD_69:before, #TD_79:before*/

#TD_54,
#TD_56,
#TD_60,
#TD_64,
#TD_66,
#TD_70,
#TD_74,
#TD_76 {
  block-size: 44px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  box-sizing: border-box;
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  fill: rgb(115, 115, 115);
  height: 44px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 12px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 22px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 22px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(115, 115, 115);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
  padding: 12px 16px;
} /*#TD_54, #TD_56, #TD_60, #TD_64, #TD_66, #TD_70, #TD_74, #TD_76*/

#TD_54:after,
#TD_56:after,
#TD_60:after,
#TD_64:after,
#TD_66:after,
#TD_70:after,
#TD_74:after,
#TD_76:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_54:after, #TD_56:after, #TD_60:after, #TD_64:after, #TD_66:after, #TD_70:after, #TD_74:after, #TD_76:after*/

#TD_54:before,
#TD_56:before,
#TD_60:before,
#TD_64:before,
#TD_66:before,
#TD_70:before,
#TD_74:before,
#TD_76:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_54:before, #TD_56:before, #TD_60:before, #TD_64:before, #TD_66:before, #TD_70:before, #TD_74:before, #TD_76:before*/

#TD_55,
#TD_62,
#TD_72 {
  block-size: 44px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  box-sizing: border-box;
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  display: block;
  fill: rgb(229, 9, 20);
  height: 44px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 12px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 22px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 22px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(229, 9, 20);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
  padding: 12px 16px;
} /*#TD_55, #TD_62, #TD_72*/

#TD_55:after,
#TD_62:after,
#TD_72:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#TD_55:after, #TD_62:after, #TD_72:after*/

#TD_55:before,
#TD_62:before,
#TD_72:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#TD_55:before, #TD_62:before, #TD_72:before*/

#TD_57 {
  block-size: 44px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  box-sizing: border-box;
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  fill: rgb(115, 115, 115);
  height: 44px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 12px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 22px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 22px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(115, 115, 115);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
  padding: 12px 16px;
} /*#TD_57*/

#TD_57:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_57:after*/

#TD_57:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_57:before*/

#DIV_61,
#DIV_65,
#DIV_71,
#DIV_75 {
  block-size: 20px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  height: 20px;
  inline-size: 121.725px;
  perspective-origin: 60.8625px 10px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 10px;
  width: 121.725px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_61, #DIV_65, #DIV_71, #DIV_75*/

#DIV_61:after,
#DIV_65:after,
#DIV_71:after,
#DIV_75:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_61:after, #DIV_65:after, #DIV_71:after, #DIV_75:after*/

#DIV_61:before,
#DIV_65:before,
#DIV_71:before,
#DIV_75:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_61:before, #DIV_65:before, #DIV_71:before, #DIV_75:before*/

#DIV_63,
#DIV_73 {
  block-size: 20px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  height: 20px;
  inline-size: 121.725px;
  perspective-origin: 60.8625px 10px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 10px;
  width: 121.725px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_63, #DIV_73*/

#DIV_63:after,
#DIV_73:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_63:after, #DIV_73:after*/

#DIV_63:before,
#DIV_73:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_63:before, #DIV_73:before*/

#DIV_67,
#DIV_77 {
  block-size: 20px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  height: 20px;
  inline-size: 121.725px;
  perspective-origin: 60.8625px 10px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 10px;
  width: 121.725px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_67, #DIV_77*/

#DIV_67:after,
#DIV_77:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_67:after, #DIV_77:after*/

#DIV_67:before,
#DIV_77:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_67:before, #DIV_77:before*/

#TR_78 {
  align-items: start;
  block-size: 248px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: flex;
  height: 248px;
  inline-size: 946px;
  min-block-size: 60px;
  min-height: 60px;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 473px 124px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 473px 124px;
  vertical-align: middle;
  width: 946px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_78*/

#TR_78:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_78:after*/

#TR_78:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-collapse: collapse;
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#TR_78:before*/

#TD_80 {
  block-size: 134px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  box-sizing: border-box;
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  fill: rgb(115, 115, 115);
  height: 134px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 8px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 67px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 67px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(115, 115, 115);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
  padding: 8px 16px 12px;
} /*#TD_80*/

#TD_80:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_80:after*/

#TD_80:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_80:before*/

#DIV_81,
#DIV_85,
#DIV_107,
#DIV_111,
#DIV_115,
#DIV_119,
#DIV_124,
#DIV_128,
#DIV_132,
#DIV_136 {
  block-size: 51px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  height: 51px;
  inline-size: 121.725px;
  perspective-origin: 60.8625px 25.5px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 25.5px;
  width: 121.725px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_81, #DIV_85, #DIV_107, #DIV_111, #DIV_115, #DIV_119, #DIV_124, #DIV_128, #DIV_132, #DIV_136*/

#DIV_81:after,
#DIV_85:after,
#DIV_107:after,
#DIV_111:after,
#DIV_115:after,
#DIV_119:after,
#DIV_124:after,
#DIV_128:after,
#DIV_132:after,
#DIV_136:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_81:after, #DIV_85:after, #DIV_107:after, #DIV_111:after, #DIV_115:after, #DIV_119:after, #DIV_124:after, #DIV_128:after, #DIV_132:after, #DIV_136:after*/

#DIV_81:before,
#DIV_85:before,
#DIV_107:before,
#DIV_111:before,
#DIV_115:before,
#DIV_119:before,
#DIV_124:before,
#DIV_128:before,
#DIV_132:before,
#DIV_136:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_81:before, #DIV_85:before, #DIV_107:before, #DIV_111:before, #DIV_115:before, #DIV_119:before, #DIV_124:before, #DIV_128:before, #DIV_132:before, #DIV_136:before*/

#svg_82,
#svg_86,
#svg_108,
#svg_112,
#svg_116,
#svg_120,
#svg_125,
#svg_129,
#svg_133,
#svg_137 {
  block-size: 26px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: none;
  height: 26px;
  inline-size: 26px;
  margin-block-end: -2px;
  margin-block-start: 6px;
  perspective-origin: 13px 13px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 13px 13px;
  width: 26px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 6px 0px -2px;
  outline: rgb(115, 115, 115) none 0px;
  overflow: hidden;
} /*#svg_82, #svg_86, #svg_108, #svg_112, #svg_116, #svg_120, #svg_125, #svg_129, #svg_133, #svg_137*/

#svg_82:after,
#svg_86:after,
#svg_108:after,
#svg_112:after,
#svg_116:after,
#svg_120:after,
#svg_125:after,
#svg_129:after,
#svg_133:after,
#svg_137:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: none;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#svg_82:after, #svg_86:after, #svg_108:after, #svg_112:after, #svg_116:after, #svg_120:after, #svg_125:after, #svg_129:after, #svg_133:after, #svg_137:after*/

#svg_82:before,
#svg_86:before,
#svg_108:before,
#svg_112:before,
#svg_116:before,
#svg_120:before,
#svg_125:before,
#svg_129:before,
#svg_133:before,
#svg_137:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: none;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#svg_82:before, #svg_86:before, #svg_108:before, #svg_112:before, #svg_116:before, #svg_120:before, #svg_125:before, #svg_129:before, #svg_133:before, #svg_137:before*/

#path_83,
#path_109,
#path_126 {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  d: path(
    "M 6 0 C 4.89543 0 4 0.895431 4 2 V 22 C 4 23.1046 4.89543 24 6 24 H 18 C 19.1046 24 20 23.1046 20 22 V 2 C 20 0.89543 19.1046 0 18 0 H 6 Z M 6 2 L 18 2 V 22 H 6 V 2 Z M 13.5 18.5 C 13.5 17.6716 12.8284 17 12 17 C 11.1716 17 10.5 17.6716 10.5 18.5 C 10.5 19.3284 11.1716 20 12 20 C 12.8284 20 13.5 19.3284 13.5 18.5 Z"
  );
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_83, #path_109, #path_126*/

#path_83:after,
#path_109:after,
#path_126:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_83:after, #path_109:after, #path_126:after*/

#path_83:before,
#path_109:before,
#path_126:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_83:before, #path_109:before, #path_126:before*/

#DIV_84,
#DIV_88,
#DIV_110,
#DIV_114,
#DIV_118,
#DIV_122,
#DIV_127,
#DIV_131,
#DIV_135,
#DIV_139 {
  block-size: 17px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  height: 17px;
  inline-size: 121.725px;
  margin-block-end: 6px;
  perspective-origin: 60.8625px 8.5px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 8.5px;
  width: 121.725px;
  border: 0px none rgb(115, 115, 115);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0px 0px 6px;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_84, #DIV_88, #DIV_110, #DIV_114, #DIV_118, #DIV_122, #DIV_127, #DIV_131, #DIV_135, #DIV_139*/

#DIV_84:after,
#DIV_88:after,
#DIV_110:after,
#DIV_114:after,
#DIV_118:after,
#DIV_122:after,
#DIV_127:after,
#DIV_131:after,
#DIV_135:after,
#DIV_139:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_84:after, #DIV_88:after, #DIV_110:after, #DIV_114:after, #DIV_118:after, #DIV_122:after, #DIV_127:after, #DIV_131:after, #DIV_135:after, #DIV_139:after*/

#DIV_84:before,
#DIV_88:before,
#DIV_110:before,
#DIV_114:before,
#DIV_118:before,
#DIV_122:before,
#DIV_127:before,
#DIV_131:before,
#DIV_135:before,
#DIV_139:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#DIV_84:before, #DIV_88:before, #DIV_110:before, #DIV_114:before, #DIV_118:before, #DIV_122:before, #DIV_127:before, #DIV_131:before, #DIV_135:before, #DIV_139:before*/

#path_87,
#path_113,
#path_130 {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  d: path(
    "M 2 3 C 0.895431 3 0 3.89543 0 5 V 19 C 0 20.1046 0.895431 21 2 21 H 22 C 23.1046 21 24 20.1046 24 19 V 5 C 24 3.89543 23.1046 3 22 3 H 2 Z M 2 5 H 22 V 19 H 2 V 5 Z M 18.5 13.5 C 19.3284 13.5 20 12.8284 20 12 C 20 11.1716 19.3284 10.5 18.5 10.5 C 17.6716 10.5 17 11.1716 17 12 C 17 12.8284 17.6716 13.5 18.5 13.5 Z"
  );
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_87, #path_113, #path_130*/

#path_87:after,
#path_113:after,
#path_130:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_87:after, #path_113:after, #path_130:after*/

#path_87:before,
#path_113:before,
#path_130:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_87:before, #path_113:before, #path_130:before*/

#TD_89 {
  block-size: 248px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  box-sizing: border-box;
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  display: block;
  fill: rgb(229, 9, 20);
  height: 248px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 8px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 124px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 124px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(229, 9, 20);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
  padding: 8px 16px 12px;
} /*#TD_89*/

#TD_89:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#TD_89:after*/

#TD_89:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#TD_89:before*/

#DIV_90,
#DIV_94,
#DIV_98,
#DIV_102 {
  block-size: 51px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  height: 51px;
  inline-size: 121.725px;
  perspective-origin: 60.8625px 25.5px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 25.5px;
  width: 121.725px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_90, #DIV_94, #DIV_98, #DIV_102*/

#DIV_90:after,
#DIV_94:after,
#DIV_98:after,
#DIV_102:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_90:after, #DIV_94:after, #DIV_98:after, #DIV_102:after*/

#DIV_90:before,
#DIV_94:before,
#DIV_98:before,
#DIV_102:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_90:before, #DIV_94:before, #DIV_98:before, #DIV_102:before*/

#svg_91,
#svg_95,
#svg_99,
#svg_103 {
  block-size: 26px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: none;
  height: 26px;
  inline-size: 26px;
  margin-block-end: -2px;
  margin-block-start: 6px;
  perspective-origin: 13px 13px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 13px 13px;
  width: 26px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 6px 0px -2px;
  outline: rgb(229, 9, 20) none 0px;
  overflow: hidden;
} /*#svg_91, #svg_95, #svg_99, #svg_103*/

#svg_91:after,
#svg_95:after,
#svg_99:after,
#svg_103:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: none;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#svg_91:after, #svg_95:after, #svg_99:after, #svg_103:after*/

#svg_91:before,
#svg_95:before,
#svg_99:before,
#svg_103:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: none;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#svg_91:before, #svg_95:before, #svg_99:before, #svg_103:before*/

#path_92 {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  d: path(
    "M 6 0 C 4.89543 0 4 0.895431 4 2 V 22 C 4 23.1046 4.89543 24 6 24 H 18 C 19.1046 24 20 23.1046 20 22 V 2 C 20 0.89543 19.1046 0 18 0 H 6 Z M 6 2 L 18 2 V 22 H 6 V 2 Z M 13.5 18.5 C 13.5 17.6716 12.8284 17 12 17 C 11.1716 17 10.5 17.6716 10.5 18.5 C 10.5 19.3284 11.1716 20 12 20 C 12.8284 20 13.5 19.3284 13.5 18.5 Z"
  );
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_92*/

#path_92:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_92:after*/

#path_92:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_92:before*/

#DIV_93,
#DIV_97,
#DIV_101,
#DIV_105 {
  block-size: 17px;
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  height: 17px;
  inline-size: 121.725px;
  margin-block-end: 6px;
  perspective-origin: 60.8625px 8.5px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 60.8625px 8.5px;
  width: 121.725px;
  border: 0px none rgb(229, 9, 20);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0px 0px 6px;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_93, #DIV_97, #DIV_101, #DIV_105*/

#DIV_93:after,
#DIV_97:after,
#DIV_101:after,
#DIV_105:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_93:after, #DIV_97:after, #DIV_101:after, #DIV_105:after*/

#DIV_93:before,
#DIV_97:before,
#DIV_101:before,
#DIV_105:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 12px / 17px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#DIV_93:before, #DIV_97:before, #DIV_101:before, #DIV_105:before*/

#path_96 {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  d: path(
    "M 2 3 C 0.895431 3 0 3.89543 0 5 V 19 C 0 20.1046 0.895431 21 2 21 H 22 C 23.1046 21 24 20.1046 24 19 V 5 C 24 3.89543 23.1046 3 22 3 H 2 Z M 2 5 H 22 V 19 H 2 V 5 Z M 18.5 13.5 C 19.3284 13.5 20 12.8284 20 12 C 20 11.1716 19.3284 10.5 18.5 10.5 C 17.6716 10.5 17 11.1716 17 12 C 17 12.8284 17.6716 13.5 18.5 13.5 Z"
  );
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_96*/

#path_96:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_96:after*/

#path_96:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_96:before*/

#path_100 {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  d: path(
    "M 3.99997 3 C 2.8954 3 1.99997 3.89543 1.99997 5 V 14 C 1.99997 15.1046 2.8954 16 3.99997 16 H 20 C 21.1045 16 22 15.1046 22 14 V 5 C 22 3.89543 21.1045 3 20 3 H 3.99997 Z M 3.99997 5 L 20 5 V 14 H 3.99997 V 5 Z M 1.11859 20.6355 C 4.58689 20.2212 8.23466 20 12 20 C 15.7653 20 19.413 20.2212 22.8813 20.6355 L 23.1186 18.6497 C 19.5701 18.2257 15.8431 18 12 18 C 8.15686 18 4.42984 18.2257 0.881348 18.6497 L 1.11859 20.6355 Z"
  );
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_100*/

#path_100:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_100:after*/

#path_100:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_100:before*/

#path_104 {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  d: path(
    "M 2 3 C 0.895431 3 0 3.89543 0 5 V 14 C 0 15.1046 0.895431 16 2 16 H 22 C 23.1046 16 24 15.1046 24 14 V 5 C 24 3.89543 23.1046 3 22 3 H 2 Z M 2 5 L 22 5 V 14 H 2 V 5 Z M 2.1072 20.5232 C 5.27786 20.1813 8.59078 20 12 20 C 15.4092 20 18.7221 20.1813 21.8928 20.5232 L 22.1072 18.5347 C 18.8643 18.1851 15.4798 18 12 18 C 8.52024 18 5.13573 18.1851 1.8928 18.5347 L 2.1072 20.5232 Z"
  );
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_104*/

#path_104:after {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_104:after*/

#path_104:before {
  border-block-end-color: rgb(229, 9, 20);
  border-block-start-color: rgb(229, 9, 20);
  border-collapse: collapse;
  border-inline-end-color: rgb(229, 9, 20);
  border-inline-start-color: rgb(229, 9, 20);
  caret-color: rgb(229, 9, 20);
  clip-rule: evenodd;
  color: rgb(229, 9, 20);
  column-rule-color: rgb(229, 9, 20);
  fill: rgb(229, 9, 20);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(229, 9, 20);
  text-size-adjust: 100%;
  border: 0px none rgb(229, 9, 20);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(229, 9, 20) none 0px;
} /*#path_104:before*/

#TD_106,
#TD_123 {
  block-size: 248px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  box-sizing: border-box;
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  fill: rgb(115, 115, 115);
  height: 248px;
  inline-size: 153.725px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 12px;
  padding-block-start: 8px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 76.8625px 124px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 76.8625px 124px;
  vertical-align: middle;
  width: 153.725px;
  border: 0px none rgb(115, 115, 115);
  flex: 1 1 auto;
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
  padding: 8px 16px 12px;
} /*#TD_106, #TD_123*/

#TD_106:after,
#TD_123:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_106:after, #TD_123:after*/

#TD_106:before,
#TD_123:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#TD_106:before, #TD_123:before*/

#path_117,
#path_134 {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  d: path(
    "M 3.99997 3 C 2.8954 3 1.99997 3.89543 1.99997 5 V 14 C 1.99997 15.1046 2.8954 16 3.99997 16 H 20 C 21.1045 16 22 15.1046 22 14 V 5 C 22 3.89543 21.1045 3 20 3 H 3.99997 Z M 3.99997 5 L 20 5 V 14 H 3.99997 V 5 Z M 1.11859 20.6355 C 4.58689 20.2212 8.23466 20 12 20 C 15.7653 20 19.413 20.2212 22.8813 20.6355 L 23.1186 18.6497 C 19.5701 18.2257 15.8431 18 12 18 C 8.15686 18 4.42984 18.2257 0.881348 18.6497 L 1.11859 20.6355 Z"
  );
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_117, #path_134*/

#path_117:after,
#path_134:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_117:after, #path_134:after*/

#path_117:before,
#path_134:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_117:before, #path_134:before*/

#path_121,
#path_138 {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  d: path(
    "M 2 3 C 0.895431 3 0 3.89543 0 5 V 14 C 0 15.1046 0.895431 16 2 16 H 22 C 23.1046 16 24 15.1046 24 14 V 5 C 24 3.89543 23.1046 3 22 3 H 2 Z M 2 5 L 22 5 V 14 H 2 V 5 Z M 2.1072 20.5232 C 5.27786 20.1813 8.59078 20 12 20 C 15.4092 20 18.7221 20.1813 21.8928 20.5232 L 22.1072 18.5347 C 18.8643 18.1851 15.4798 18 12 18 C 8.52024 18 5.13573 18.1851 1.8928 18.5347 L 2.1072 20.5232 Z"
  );
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  perspective-origin: 0px 0px;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_121, #path_138*/

#path_121:after,
#path_138:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_121:after, #path_138:after*/

#path_121:before,
#path_138:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-collapse: collapse;
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  clip-rule: evenodd;
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  fill: rgb(115, 115, 115);
  fill-rule: evenodd;
  text-align: center;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 700 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#path_121:before, #path_138:before*/

#SMALL_140 {
  block-size: 32px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  height: 32px;
  inline-size: 780px;
  padding-inline-end: 150px;
  padding-inline-start: 16px;
  perspective-origin: 473px 16px;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 473px 16px;
  width: 780px;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
  padding: 0px 150px 0px 16px;
} /*#SMALL_140*/

#SMALL_140:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SMALL_140:after*/

#SMALL_140:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SMALL_140:before*/

#SPAN_141,
#SPAN_144 {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SPAN_141, #SPAN_144*/

#SPAN_141:after,
#SPAN_144:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SPAN_141:after, #SPAN_144:after*/

#SPAN_141:before,
#SPAN_144:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SPAN_141:before, #SPAN_144:before*/

#A_142 {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(0, 113, 235);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_142*/

#A_142:after {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_142:after*/

#A_142:before {
  border-block-end-color: rgb(0, 113, 235);
  border-block-start-color: rgb(0, 113, 235);
  border-inline-end-color: rgb(0, 113, 235);
  border-inline-start-color: rgb(0, 113, 235);
  caret-color: rgb(0, 113, 235);
  color: rgb(0, 113, 235);
  column-rule-color: rgb(0, 113, 235);
  text-decoration: none solid rgb(0, 113, 235);
  text-size-adjust: 100%;
  border: 0px none rgb(0, 113, 235);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(0, 113, 235) none 0px;
} /*#A_142:before*/

#SMALL_143 {
  block-size: 32px;
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  display: block;
  height: 32px;
  inline-size: 780px;
  margin-block-start: 10px;
  padding-inline-end: 150px;
  padding-inline-start: 16px;
  perspective-origin: 473px 16px;
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  transform-origin: 473px 16px;
  width: 780px;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 10px 0px 0px;
  outline: rgb(115, 115, 115) none 0px;
  padding: 0px 150px 0px 16px;
} /*#SMALL_143*/

#SMALL_143:after {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SMALL_143:after*/

#SMALL_143:before {
  border-block-end-color: rgb(115, 115, 115);
  border-block-start-color: rgb(115, 115, 115);
  border-inline-end-color: rgb(115, 115, 115);
  border-inline-start-color: rgb(115, 115, 115);
  caret-color: rgb(115, 115, 115);
  color: rgb(115, 115, 115);
  column-rule-color: rgb(115, 115, 115);
  text-decoration: none solid rgb(115, 115, 115);
  text-size-adjust: 100%;
  border: 0px none rgb(115, 115, 115);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(115, 115, 115) none 0px;
} /*#SMALL_143:before*/

#DIV_145 {
  block-size: 64px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 64px;
  inline-size: 440px;
  margin-block-start: 24px;
  margin-inline-end: 237px;
  margin-inline-start: 237px;
  max-inline-size: 440px;
  max-width: 440px;
  padding-inline-end: 16px;
  padding-inline-start: 16px;
  perspective-origin: 236px 32px;
  text-align: center;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 236px 32px;
  width: 440px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 24px 237px 0px;
  outline: rgb(51, 51, 51) none 0px;
  padding: 0px 16px;
} /*#DIV_145*/

#DIV_145:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: center;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_145:after*/

#DIV_145:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-align: center;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_145:before*/

#BUTTON_146 {
  appearance: button;
  block-size: 64px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-end-style: none;
  border-block-end-width: 0px;
  border-block-start-color: rgb(255, 255, 255);
  border-block-start-style: none;
  border-block-start-width: 0px;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-end-style: none;
  border-inline-end-width: 0px;
  border-inline-start-color: rgb(255, 255, 255);
  border-inline-start-style: none;
  border-inline-start-width: 0px;
  border-start-end-radius: 4px;
  border-start-start-radius: 4px;
  bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px 0px;
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: pointer;
  height: 64px;
  inline-size: 440px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  letter-spacing: 0.4px;
  min-block-size: 64px;
  min-height: 64px;
  min-inline-size: 110px;
  min-width: 110px;
  padding-block-end: 12px;
  padding-block-start: 12px;
  padding-inline-end: 25.3333px;
  padding-inline-start: 25.3333px;
  perspective-origin: 220px 32px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 220px 32px;
  user-select: none;
  vertical-align: middle;
  width: 440px;
  background: rgb(229, 9, 20) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  border-radius: 4px;
  font: 500 24px / 24px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  padding: 12px 25.3333px;
} /*#BUTTON_146*/

#BUTTON_146:after {
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: pointer;
  letter-spacing: 0.4px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  user-select: none;
  border: 0px none rgb(255, 255, 255);
  font: 500 24px / 24px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#BUTTON_146:after*/

#BUTTON_146:before {
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  cursor: pointer;
  letter-spacing: 0.4px;
  text-decoration: none solid rgb(255, 255, 255);
  text-size-adjust: 100%;
  user-select: none;
  border: 0px none rgb(255, 255, 255);
  font: 500 24px / 24px "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  outline: rgb(255, 255, 255) none 0px;
} /*#BUTTON_146:before*/

#DIV_147 {
  block-size: 193px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  bottom: 0px;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 193px;
  inline-size: 1519.2px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: 190px;
  min-width: 190px;
  padding-block-end: 20px;
  perspective-origin: 759.6px 106.5px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 759.6px 106.5px;
  width: 1519.2px;
  background: rgb(243, 243, 243) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
  padding: 0px 0px 20px;
} /*#DIV_147*/

#DIV_147:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_147:after*/

#DIV_147:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_147:before*/

#DIV_148 {
  block-size: 0px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(229, 229, 229);
  border-block-start-style: solid;
  border-block-start-width: 1px;
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 0px;
  inline-size: 1519.2px;
  perspective-origin: 759.6px 0.5px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 759.6px 0.5px;
  width: 1519.2px;
  border-top: 1px solid rgb(229, 229, 229);
  border-right: 0px none rgb(117, 117, 117);
  border-bottom: 0px none rgb(117, 117, 117);
  border-left: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_148*/

#DIV_148:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_148:after*/

#DIV_148:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_148:before*/

#DIV_149 {
  block-size: 162px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 162px;
  inline-size: 1367.28px;
  margin-inline-end: 75.9625px;
  margin-inline-start: 75.9625px;
  padding-block-start: 30px;
  perspective-origin: 683.638px 96px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 683.638px 96px;
  width: 1367.28px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 75.9625px;
  outline: rgb(117, 117, 117) none 0px;
  padding: 30px 0px 0px;
} /*#DIV_149*/

#DIV_149:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_149:after*/

#DIV_149:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_149:before*/

#P_150 {
  block-size: 20px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 20px;
  inline-size: 1367.28px;
  margin-block-end: 30px;
  margin-block-start: 0px;
  perspective-origin: 683.638px 10px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 683.638px 10px;
  width: 1367.28px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px 0px 30px;
  outline: rgb(117, 117, 117) none 0px;
} /*#P_150*/

#P_150:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#P_150:after*/

#P_150:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#P_150:before*/

#A_151 {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  perspective-origin: 0px 0px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_151*/

#A_151:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_151:after*/

#A_151:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_151:before*/

#UL_152 {
  block-size: 64px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  box-sizing: border-box;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 64px;
  inline-size: 1000px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  max-inline-size: 1000px;
  max-width: 1000px;
  padding-inline-start: 0px;
  perspective-origin: 500px 32px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 500px 32px;
  width: 1000px;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0px;
  outline: rgb(117, 117, 117) none 0px;
  padding: 0px;
} /*#UL_152*/

#UL_152:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#UL_152:after*/

#UL_152:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#UL_152:before*/

#LI_153,
#LI_156,
#LI_159,
#LI_162,
#LI_165,
#LI_168 {
  block-size: 16px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  box-sizing: border-box;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  display: inline-block;
  height: 16px;
  inline-size: 250px;
  margin-block-end: 16px;
  min-inline-size: 100px;
  min-width: 100px;
  padding-inline-end: 12px;
  perspective-origin: 125px 8px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 125px 8px;
  vertical-align: top;
  width: 250px;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  margin: 0px 0px 16px;
  outline: rgb(117, 117, 117) none 0px;
  padding: 0px 12px 0px 0px;
} /*#LI_153, #LI_156, #LI_159, #LI_162, #LI_165, #LI_168*/

#LI_153:after,
#LI_156:after,
#LI_159:after,
#LI_162:after,
#LI_165:after,
#LI_168:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#LI_153:after, #LI_156:after, #LI_159:after, #LI_162:after, #LI_165:after, #LI_168:after*/

#LI_153:before,
#LI_156:before,
#LI_159:before,
#LI_162:before,
#LI_165:before,
#LI_168:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#LI_153:before, #LI_156:before, #LI_159:before, #LI_162:before, #LI_165:before, #LI_168:before*/

#A_154,
#A_157,
#A_160,
#A_163,
#A_166,
#A_169 {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_154, #A_157, #A_160, #A_163, #A_166, #A_169*/

#A_154:after,
#A_157:after,
#A_160:after,
#A_163:after,
#A_166:after,
#A_169:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_154:after, #A_157:after, #A_160:after, #A_163:after, #A_166:after, #A_169:after*/

#A_154:before,
#A_157:before,
#A_160:before,
#A_163:before,
#A_166:before,
#A_169:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#A_154:before, #A_157:before, #A_160:before, #A_163:before, #A_166:before, #A_169:before*/

#SPAN_155,
#SPAN_158,
#SPAN_164,
#SPAN_170 {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_155, #SPAN_158, #SPAN_164, #SPAN_170*/

#SPAN_155:after,
#SPAN_158:after,
#SPAN_164:after,
#SPAN_170:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_155:after, #SPAN_158:after, #SPAN_164:after, #SPAN_170:after*/

#SPAN_155:before,
#SPAN_158:before,
#SPAN_164:before,
#SPAN_170:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_155:before, #SPAN_158:before, #SPAN_164:before, #SPAN_170:before*/

#SPAN_161,
#SPAN_167 {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_161, #SPAN_167*/

#SPAN_161:after,
#SPAN_167:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_161:after, #SPAN_167:after*/

#SPAN_161:before,
#SPAN_167:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: pointer;
  text-align: left;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 13px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  list-style: outside none none;
  outline: rgb(117, 117, 117) none 0px;
} /*#SPAN_161:before, #SPAN_167:before*/

#DIV_171 {
  block-size: 48px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  display: inline-block;
  height: 48px;
  inline-size: 128.4px;
  perspective-origin: 64.2px 24px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 64.2px 24px;
  width: 128.4px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_171*/

#DIV_171:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_171:after*/

#DIV_171:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_171:before*/

#DIV_172 {
  block-size: 48px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  bottom: 0px;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  display: inline-block;
  height: 48px;
  inline-size: 128.4px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  max-inline-size: 500px;
  max-width: 500px;
  perspective-origin: 64.2px 24px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 64.2px 24px;
  width: 128.4px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_172*/

#DIV_172:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_172:after*/

#DIV_172:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_172:before*/

#LABEL_173 {
  block-size: 1px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 49px;
  caret-color: rgb(51, 51, 51);
  clip: rect(0px, 0px, 0px, 0px);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  display: block;
  height: 1px;
  inline-size: 1px;
  inset-block-end: 49px;
  inset-block-start: 0px;
  inset-inline-end: 129.4px;
  inset-inline-start: 0px;
  left: 0px;
  margin-block-end: -1px;
  margin-block-start: -1px;
  margin-inline-end: -1px;
  margin-inline-start: -1px;
  perspective-origin: 0.5px 0.5px;
  position: absolute;
  right: 129.4px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 0.5px 0.5px;
  white-space: nowrap;
  width: 1px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: -1px;
  outline: rgb(51, 51, 51) none 0px;
  overflow: hidden;
} /*#LABEL_173*/

#LABEL_173:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  white-space: nowrap;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LABEL_173:after*/

#LABEL_173:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  white-space: nowrap;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#LABEL_173:before*/

#DIV_174 {
  block-size: 48px;
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  bottom: 0px;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 48px;
  inline-size: 128.4px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  perspective-origin: 64.2px 24px;
  position: relative;
  right: 0px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 64.2px 24px;
  width: 128.4px;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_174*/

#DIV_174:after {
  block-size: 0px;
  border-block-end-color: rgb(255, 255, 255);
  border-block-end-style: solid;
  border-block-end-width: 6px;
  border-block-start-color: rgb(51, 51, 51);
  border-block-start-style: solid;
  border-block-start-width: 6px;
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-end-style: solid;
  border-inline-end-width: 6px;
  border-inline-start-color: rgb(255, 255, 255);
  border-inline-start-style: solid;
  border-inline-start-width: 6px;
  bottom: 12.4875px;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  /* content: '""'; */
  display: block;
  height: 0px;
  inline-size: 0px;
  inset-block-end: 12.4875px;
  inset-block-start: 23.5125px;
  inset-inline-end: 10px;
  inset-inline-start: 106.4px;
  left: 106.4px;
  perspective-origin: 6px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  top: 23.5125px;
  transform-origin: 6px 6px;
  width: 0px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border-top: 6px solid rgb(51, 51, 51);
  border-right: 6px solid rgb(255, 255, 255);
  border-bottom: 6px solid rgb(255, 255, 255);
  border-left: 6px solid rgb(255, 255, 255);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#DIV_174:after*/

#DIV_174:before {
  block-size: 19.2px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 4.8px;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  content: '""';
  display: block;
  height: 19.2px;
  inline-size: 16px;
  inset-block-end: 4.8px;
  inset-block-start: 24px;
  inset-inline-end: 97.4px;
  inset-inline-start: 15px;
  left: 15px;
  perspective-origin: 8px 9.6px;
  pointer-events: none;
  position: absolute;
  right: 97.4px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 24px;
  transform: matrix(1, 0, 0, 1, 0, -9.6);
  transform-origin: 8px 9.6px;
  width: 16px;
  border: 0px none rgb(51, 51, 51);
  font: 16px nf-icon;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_174:before*/

#SELECT_175 {
  appearance: none;
  block-size: 48px;
  border-block-end-color: rgb(166, 166, 166);
  border-block-start-color: rgb(166, 166, 166);
  border-end-end-radius: 2px;
  border-end-start-radius: 2px;
  border-inline-end-color: rgb(166, 166, 166);
  border-inline-start-color: rgb(166, 166, 166);
  border-start-end-radius: 2px;
  border-start-start-radius: 2px;
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  height: 48px;
  inline-size: 128.4px;
  padding-block-end: 12px;
  padding-block-start: 10px;
  padding-inline-end: 30px;
  padding-inline-start: 42px;
  perspective-origin: 64.2px 24px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  transform-origin: 64.2px 24px;
  width: 128.4px;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 2px;
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
  padding: 10px 30px 12px 42px;
} /*#SELECT_175*/

#SELECT_175:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#SELECT_175:after*/

#SELECT_175:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#SELECT_175:before*/

#OPTION_176,
#OPTION_177 {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: default;
  min-block-size: 19.2px;
  min-height: 19.2px;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#OPTION_176, #OPTION_177*/

#OPTION_176:after,
#OPTION_177:after {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: default;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#OPTION_176:after, #OPTION_177:after*/

#OPTION_176:before,
#OPTION_177:before {
  border-block-end-color: rgb(117, 117, 117);
  border-block-start-color: rgb(117, 117, 117);
  border-inline-end-color: rgb(117, 117, 117);
  border-inline-start-color: rgb(117, 117, 117);
  caret-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  column-rule-color: rgb(117, 117, 117);
  cursor: default;
  text-decoration: none solid rgb(117, 117, 117);
  text-size-adjust: 100%;
  border: 0px none rgb(117, 117, 117);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(117, 117, 117) none 0px;
} /*#OPTION_176:before, #OPTION_177:before*/

#DIV_178 {
  block-size: 1px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  bottom: 744.6px;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  height: 1px;
  inline-size: 1px;
  inset-block-end: 744.6px;
  inset-block-start: 0px;
  inset-inline-end: 1518.2px;
  inset-inline-start: 0px;
  left: 0px;
  perspective-origin: 0.5px 0.5px;
  position: absolute;
  right: 1518.2px;
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 0.5px 0.5px;
  width: 1px;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
  overflow: hidden;
} /*#DIV_178*/

#DIV_178:after {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_178:after*/

#DIV_178:before {
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  text-decoration: none solid rgb(51, 51, 51);
  text-size-adjust: 100%;
  border: 0px none rgb(51, 51, 51);
  font: 16px "Netflix Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: rgb(51, 51, 51) none 0px;
} /*#DIV_178:before*/
#DIV_1 {
  margin: auto;
}
.bigertr {
  display: flex;
  max-height: 520px !important;
}
.innertr4 {
  display: flex;
  flex-direction: column;
}
.inner2tr4 {
  margin: auto;
  display: flex;
  flex: auto;
}
#TBODY_51 td {
  color: white !important;
}
#DIV_13 {
  color: white !important;
  text-align: center !important;
}
#DIV_13 h1 {
  color: white !important;
}
#DIV_35 span {
  text-align: center;
}

.pinkbox {
  background: #db03ac42;
  color: white !important;
  border: 2px solid #f713e7 !important;
  border-radius: 5px;
}
.pinkbox div {
  color: white !important;
}
.pricetag {
  width: 119px;
}
.buybutton {
  width: 100% !important;
  border-radius: 0 !important;
  background-color: #fc6100 !important;
  border: 1px solid #fc6100;
}

/* .buybutton1 {
  width: 50% !important;
  border-radius: 0 !important;
  background-color: #e20e89 !important;
  border: 1px solid #e20e89;
} */
.span1 {
  background-color: #e20e89 !important;
}

.buybutton2 {
  width: 100% !important;
  border-radius: 0 !important;
  background-color: #e1ad01 !important;
  border: 1px solid #e1ad01;
}
.span2 {
  background-color: #e1ad01 !important;
}
/* .buybutton3 {
  width: 50% !important;
  border-radius: 0 !important;
  background-color: #00acc8 !important;
  border: 1px solid #00acc8;
} */
.span3 {
  background-color: #00acc8 !important;
}
.scrolleroftable {
  overflow-y: scroll;
  overflow-x: hidden;
}
.appover {
  height: 900px !important;
}
#DIV_9 {
  max-height: 200px;
  height: 200px;
}
#DIV_10 {
  height: 501px;
}
.card9 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  color: white;
  text-align: center;
  /* height: 85vh; */
  font-family: arial;
}

.price9 {
  color: white;
  font-size: 22px;
}

.card9 button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card9 button:hover {
  opacity: 0.7;
}
.mobile-view {
  height: 100vh;
}
.owl-stage {
  height: 93vh;
}
.border-pinkbutton {
  border: 2px solid #ef3691 !important;
  border-radius: 5px;
  background-color: #762626 !important;
  width: 70% !important;
}
.p-15 {
  padding: 15px;
  font-size: 16px;
}
.uli {
  padding: 0;
}

.scroll-uli {
  height: 364px;
  overflow: scroll;
}
.uli li {
  text-align: initial;

  margin-left: 10px;
}
.uli div {
  padding: 5%;
  margin: 10px;
  display: flex;
  display: flex;
}
.buy-now {
  color: white;
  padding: 3% 29%;

  border-radius: 5px;
}
.orange-button {
  border: #fc6100 !important;
  background: #fc6100 !important;
}

.pinks-button {
  background-color: #e20e89 !important;
  border: 1px solid #e20e89;
}

.yellow-button {
  background-color: #e1ad01 !important;
  border: 1px solid #e1ad01;
}
.skyblue-button {
  background-color: #00acc8 !important;
  border: 1px solid #00acc8;
}
.owl-dot {
  display: none !important;
}
.innerprice {
  position: relative;
  text-align: center;
  /* bottom: -58px; */
}
.price-tag {
  padding: 7% 30% !important;
}
