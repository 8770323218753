table{
border-spacing:20px;
}

.upcoming-scan table tr td{
text-align:left;
}

.upcoming-scan #white{
background:#fff;
color:#DD32A4;
border-radius:10px;
padding:10px;
}

.upcoming-scan #outline{
border:1px solid #fff;
padding:10px;
border-radius:10px;
}
