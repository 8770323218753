/* Add CSS styles to control the appearance of the modal */
.modal {
  
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color:black;
  }
  
  .modal-content {
    border-radius: 25px;
    background-color: #fefefe;
    overflow-x:auto;
    border: 1px solid #888;
    width: 80%;
    color:black;
    
  }
  
  