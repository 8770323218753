.card-containerx {
  display: flex;
  justify-content: center;
  text-align: center;
}

.cardx {
  /* background-color: rgba(245, 245, 245, 0.8); */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  width: 300px;
  color: aliceblue;

  backdrop-filter: blur(3px) saturate(52%);
  -webkit-backdrop-filter: blur(3px) saturate(52%);
  background-color: rgba(255, 255, 255, 0.26);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.h1x {
  color: white;
  text-align: center;
}
.h3x {
  font-size: 24px;
  margin-bottom: 10px;
}

.h4x {
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}

.ulx {
  list-style-type: none;
  padding-left: 20px;
  /* margin: 0; */
  padding: 0;
}

.ulx li {
  margin-bottom: 5px;
}

.buttonx {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.inbuild {
  font-size: 16px;
}
